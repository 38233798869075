import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { SmallBodyText, BoldSmallBodyText, Subheading } from '../styles/Text'
import { FlexColumn, SpaceBetweenRowContainer } from '../styles/Containers'

const Label = styled(BoldSmallBodyText)`
  width: 40%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const Value = styled(SmallBodyText)`
  width: 60%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

function DetailSection({ sectionLabel, details }) {
  return (
    <FlexColumn gap="15px">
      {sectionLabel && <Subheading>{sectionLabel}</Subheading>}
      <FlexColumn gap="10px">
        {Object.keys(details).map(d => (
          <SpaceBetweenRowContainer key={d} gap="5px 15px">
            <Label>{d}</Label>
            <Value>{details[d]}</Value>
          </SpaceBetweenRowContainer>
        ))}
      </FlexColumn>
    </FlexColumn>
  )
}

DetailSection.propTypes = {
  sectionLabel: PropTypes.string,
  details: PropTypes.object.isRequired
}

export default DetailSection
