import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../../themes/theme'
import { CenteredFlexColumn } from '../styles/Containers'
import BodyText, { LargeTitle } from '../styles/Text'
import Link from '../styles/Link'

const ColoredText = styled.h1`
  font-size: 250px;
  line-height: 250px;
  font-weight: 800;
  margin: 0;

  background: linear-gradient(180deg, ${theme.colors.primaryLight} 29.69%, ${theme.colors.primary} 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const NotFoundPage = () => (
  <CenteredFlexColumn>
    <ColoredText>404</ColoredText>
    <LargeTitle>Sorry, page not found.</LargeTitle>
    <BodyText>
      Looking to make a Property Information Request?&nbsp;
      <Link to="/">Get started</Link>
    </BodyText>
  </CenteredFlexColumn>
)

export default NotFoundPage
