import React from 'react'
import PropTypes from 'prop-types'
import FormInput from '../inputFields/FormInput'
import MaskedInput from '../inputFields/MaskedInput'
import { FlexColumn, FormRow } from '../styles/Containers'
import { FormTitle } from '../styles/Text'

function OwnerForm({ loading = false }) {
  return (
    <FlexColumn>
      <FormTitle>Owner Information</FormTitle>
      <FormRow>
        <FormInput required={true} id="ownerInformation.firstName" label="First Name" disabled={loading} />
        <FormInput required={true} id="ownerInformation.lastName" label="Last Name" disabled={loading} />
        <FormInput required={true} id="ownerInformation.address" label="Address" disabled={loading} />
        <MaskedInput required={true} id="ownerInformation.phone" label="Phone" format="(###) ###-####" disabled={loading} />
        <FormInput required={true} id="ownerInformation.email" label="Email" disabled={loading} />
      </FormRow>
    </FlexColumn>
  )
}

OwnerForm.propTypes = {
  loading: PropTypes.bool
}

export default OwnerForm
