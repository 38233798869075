function formatAddress(info) {
  const { address, unit, city, province, postalCode } = info
  const formattedUnit = unit ? `${unit}-` : ''
  return `${formattedUnit}${address}, ${city}, ${province} ${postalCode}`
}

function formatName(info) {
  return `${info.firstName} ${info.lastName}`
}

function formatPhone(phone) {
  return phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

function formatSectionInformation(info) {
  const values = {
    Name: formatName(info),
    Address: info.address,
    Phone: formatPhone(info.phone),
    Email: info.email
  }

  if (info.additionalName) values['Additional Name'] = info.additionalName

  return values
}

function formatPIRDetailsForDisplay({ propertyInformation, ownerInformation, strataInformation, requestorInformation } = {}) {
  const formattedDetails = {
    propertyInformation: { 'Property Address': formatAddress(propertyInformation) },
    requestorInformation: {
      'Agency / Brokerage Name': requestorInformation.brokerageName,
      ...formatSectionInformation(requestorInformation)
    }
  }

  if (ownerInformation.companyName) {
    formattedDetails.ownerInformation = {
      'Company Details': { 'Company Name': ownerInformation.companyName },
      'Authorized Signer Contact Information': formatSectionInformation(ownerInformation)
    }
  } else {
    formattedDetails.ownerInformation = formatSectionInformation(ownerInformation)
  }

  if (strataInformation) {
    formattedDetails.strataInformation = {
      'Strata Details': { 'Strata Number': strataInformation.strataNumber },
      'Contact Information': {
        Role: strataInformation.role,
        ...formatSectionInformation(strataInformation)
      }
    }
  }

  return formattedDetails
}

function formatPIRForOrderSummary({ propertyInformation, ownerInformation, strataInformation, requestorInformation } = {}) {
  const ownerName = ownerInformation.companyName ? ownerInformation.companyName : formatName(ownerInformation)
  const authorizedSigner = ownerInformation.companyName && formatName(ownerInformation)

  const details = { 'Property Address': formatAddress(propertyInformation) }
  details['Owner Name'] = ownerName
  if (authorizedSigner) details['Authorized Signer Name'] = authorizedSigner
  if (strataInformation) details['Strata Contact'] = formatName(strataInformation)
  details['Permission Given To'] = formatName(requestorInformation)

  return details
}

export { formatAddress, formatName, formatPhone, formatPIRDetailsForDisplay, formatPIRForOrderSummary }
