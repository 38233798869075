import React from 'react'
import styled from '@emotion/styled'
import { Outlet, useLocation } from 'react-router-dom'
import { theme } from '../themes/theme'
import SupportPopper from '../components/common/SupportPopper'
import PoweredBy from '../components/common/PoweredBy'

const Page = styled.div`
  background: ${props => (props.bg === 'light' ? theme.colors.background.light : theme.colors.background.white)};
  padding: 0 0 50px;

  display: flex;
  flex-direction: column;
  gap: 0;

  width: 100%;
  min-height: calc(100vh - 50px);
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: calc(100vh - 85px);
  min-width: 100vw;
`

const HelpContainer = styled.div`
  position: fixed;
  right: 28px;
  bottom: 28px;
`

const supportProps = {
  text: 'Have a question?',
  email: 'pir@kelowna.ca',
  phone: '(250) 469-8960'
}

const lightBg = ['/login', '/resetpassword']

export const UnprotectedRoute = () => {
  const location = useLocation()

  return (
    <Page bg={lightBg.includes(location.pathname) ? 'light' : 'white'}>
      <Body>
        <Outlet />
      </Body>
      {location.pathname !== '/login' && (
        <HelpContainer>
          <SupportPopper {...supportProps} />
        </HelpContainer>
      )}
      <PoweredBy />
    </Page>
  )
}
