import React from 'react'
import styled from '@emotion/styled'
import { useSearchParams } from 'react-router-dom'
import { theme } from '../../themes/theme'
import { Page, WhiteBGContainer } from '../styles/Containers'
import ForgotPasswordForm from '../authentication/ForgotPasswordForm'
import ResetPasswordForm from '../authentication/ResetPasswordForm'

const PageContainer = styled(Page)`
  background: ${theme.colors.background.light};
  min-width: 100vw;
  padding: 50px 5%;
`

const ContentContainer = styled(WhiteBGContainer)`
  max-width: 500px;
`

function ForgotPasswordPage() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <PageContainer>
      <ContentContainer>{token ? <ResetPasswordForm token={token} /> : <ForgotPasswordForm />}</ContentContainer>
    </PageContainer>
  )
}

export default ForgotPasswordPage
