import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { theme } from '../../themes/theme'
import { Button as MuiButton, CircularProgress, Fade } from '@mui/material'

const { text, primaryButton, secondaryButton } = theme.colors

const padding = {
  default: '10px 20px',
  short: '7px 20px',
  large: '13px 30px'
}

const ButtonSpinner = styled(CircularProgress)`
  position: absolute;
  margin-left: -12px;
  margin-top: -12px;
  left: 50%;
  top: 50%;
  color: ${theme.colors.primary};
`

const DefaultButton = styled(MuiButton)`
  display: flex;
  text-transform: none;
  align-items: center;
  justify-content: center;
  gap: 5px;

  border-radius: ${theme.borderRadius.button};
  background: ${primaryButton.background};
  border: 0.775px solid ${primaryButton.border};
  padding: ${props => padding[props.padding]};
  width: ${props => props.width};

  color: ${primaryButton.text};
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
  line-height: 15px;

  cursor: pointer;

  a {
    text-decoration: none;
    color: ${primaryButton.text};
  }

  .MuiSvgIcon-root {
    height: 15px;
    width: 15px;
  }

  &.Mui-disabled {
    color: ${primaryButton.disabledText};
    background: ${primaryButton.disabled};
  }

  :hover:not([disabled]) {
    background: ${primaryButton.hover};
  }

  :hover:disabled {
    cursor: default;
  }
`

const Button = ({ children, loading, padding = 'default', ...props }) => (
  <DefaultButton disabled={loading} {...props} padding={padding}>
    {loading && (
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms'
        }}
        unmountOnExit>
        <ButtonSpinner size={24} />
      </Fade>
    )}
    {children}
  </DefaultButton>
)

const SecondaryButton = styled(Button)`
  border: 0.775px solid ${secondaryButton.border};
  border-radius: ${theme.borderRadius.button};
  color: ${secondaryButton.text};
  background: ${secondaryButton.background};
  width: ${props => props.width};

  :hover:not([disabled]) {
    border: 0.775px solid ${secondaryButton.hoverBorder};
    background: ${secondaryButton.hover};
  }

  :hover:disabled {
    background: ${secondaryButton.disabled};
    cursor: default;
  }

  :disabled {
    background: ${secondaryButton.disabled};
    color: ${secondaryButton.disabledText};
    opacity: 0.5;
  }
`

const IconButton = styled(Button)`
  padding: 6px;
  border-radius: ${theme.borderRadius.iconButton};
  min-width: auto;
`

const TextButton = styled(Button)`
  color: ${text.link};
  text-decoration: underline;
  border: none;
  background: transparent;
  padding: 0;

  :hover {
    background: transparent;
    text-decoration: underline;
    color: ${text.linkDisabled};
  }

  :hover:not([disabled]) {
    background: none;
  }

  &.Mui-disabled {
    color: ${text.linkDisabled};
    background: none;
  }
`

Button.propTypes = {
  ...MuiButton.propTypes,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  padding: PropTypes.oneOf(['default', 'short', 'large'])
}

export default Button
export { SecondaryButton, IconButton, TextButton }
