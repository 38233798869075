import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { SecondaryButton } from '../common/Button.js'
import { Form } from '../styles/Containers.js'
import ErrorMessage from '../common/ErrorMessage.js'

function CheckoutForm({ paymentError = false, onSuccess = () => {} }) {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const paymentElementOptions = {
    layout: {
      type: 'accordion',
      radios: false,
      spacedAccordionItems: true
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!stripe || !elements) return
    setIsLoading(true)

    stripe
      .confirmPayment({
        elements,
        redirect: 'if_required'
      })
      .then(result => {
        if (result.error) {
          const { type, message } = error
          type === 'card_error' || type === 'validation_error' ? setError(message) : setError('An unexpected error occurred')
          console.error('stripe payment error', e)
        } else {
          onSuccess()
        }
      })

    setIsLoading(false)
  }

  return (
    <Form gap="15px" onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <SecondaryButton width="100%" type="submit" loading={isLoading} disabled={isLoading || !stripe || !elements}>
        Confirm & Pay Now
      </SecondaryButton>
      {(error || paymentError) && (
        <ErrorMessage id="payment-error">{error ? error : 'There was an error processing your payment. Please try again.'}</ErrorMessage>
      )}
    </Form>
  )
}

CheckoutForm.propTypes = {
  paymentError: PropTypes.bool,
  onSuccess: PropTypes.func
}
export default CheckoutForm
