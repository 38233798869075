import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'

import RadioGroup from '@mui/material/RadioGroup'

import FormSelect from '../inputFields/FormSelect'
import MaskedInput from '../inputFields/MaskedInput'
import FormInput from '../inputFields/FormInput'
import { theme } from '../../themes/theme'
import { FormRow, FlexColumn } from '../styles/Containers'
import { RowFormControl, StyledFormControlLabel, StyledRadio } from '../styles/RadioGroup'
import BodyText, { Subheading, ErrorText, FormTitle } from '../styles/Text'

const Red = styled.span`
  color: ${theme.colors.error};
`

function StrataForm({ loading = false }) {
  const { register, watch, formState } = useFormContext()

  const isStrata = watch('isStrata')

  const error = !!formState.errors.isStrata
  const helperText = formState.errors?.isStrata?.message

  return (
    <FlexColumn>
      <FormTitle>Strata Information</FormTitle>
      <FlexColumn gap="0">
        <RowFormControl>
          <BodyText>
            Is the property part of a strata?<Red> *</Red>
          </BodyText>
          <RadioGroup row name="is-strata-radio-group" id="isStrata">
            <StyledFormControlLabel {...register('isStrata')} value={true} control={<StyledRadio />} label="Yes" disabled={loading} />
            <StyledFormControlLabel {...register('isStrata')} value={false} control={<StyledRadio />} label="No" disabled={loading} />
          </RadioGroup>
        </RowFormControl>
        {error && <ErrorText role="alert">{helperText}</ErrorText>}
      </FlexColumn>
      {isStrata === 'true' && (
        <>
          <FlexColumn gap="10px">
            <Subheading>Strata Details</Subheading>
            <FormRow>
              <FormInput required={true} id="strataInformation.strataNumber" label="Strata Number" disabled={loading} />
            </FormRow>
          </FlexColumn>
          <FlexColumn gap="10px">
            <Subheading>Contact Information</Subheading>
            <FormRow>
              <FormSelect required={true} id="strataInformation.role" label="Role" options={['President', 'Vice President']} disabled={loading} />
              <FormInput required={true} id="strataInformation.firstName" label="First Name" disabled={loading} />
              <FormInput required={true} id="strataInformation.lastName" label="Last Name" disabled={loading} />
              <FormInput required={true} id="strataInformation.address" label="Address" disabled={loading} />
              <MaskedInput required={true} id="strataInformation.phone" label="Phone" format="(###) ###-####" disabled={loading} />
              <FormInput required={true} id="strataInformation.email" label="Email" disabled={loading} />
              <FormInput id="strataInformation.additionalName" label="Additional Name" disabled={loading} />
            </FormRow>
          </FlexColumn>
        </>
      )}
    </FlexColumn>
  )
}

StrataForm.propTypes = {
  loading: PropTypes.bool
}

export default StrataForm
