import axiosPublic from './dataClient'

const BASE_URL = process.env.REACT_APP_API_URL || 'localhost:3000'

export const postPIRData = pirData => {
  return axiosPublic.post(`${BASE_URL}/pir`, pirData)
}

export const getPaymentIntentSecret = async pirId => {
  // TODO: need to somehow useSearchParams in PaymentPage.stories to get some mocked pirId there. meanwhile
  // commenting out the error
  // if (!pirId) throw new Error('pirId missing from payment request')
  return axiosPublic.post(`${BASE_URL}/payment`, { pirId })
}

export const getPIRById = pirId => {
  return axiosPublic.get(`${BASE_URL}/pir/${pirId}`)
}

export const getPIRStatus = pirId => {
  return axiosPublic.get(`${BASE_URL}/pir/${pirId}/status`)
}

export const getPIRFileList = pirId => {
  return axiosPublic.get(`${BASE_URL}/pir/${pirId}/fileList`)
}

export const getPIRFileDownload = (pirId, fileName) => {
  return axiosPublic.get(`${BASE_URL}/download/${pirId}/${fileName}`, { responseType: 'blob' })
}

export const postDeletePIRFile = (pirId, fileName) => {
  return axiosPublic.post(`${BASE_URL}/delete/${pirId}/${fileName}`)
}

export const postUploadPIRFile = (pirId, file) => {
  const formData = new FormData()
  formData.append('file', file)

  return axiosPublic.post(`${BASE_URL}/upload/${pirId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const postPIRResponseEmail = pirId => {
  return axiosPublic.post(`${BASE_URL}/pir/${pirId}/response`)
}

export const postSecurityCode = (pirId, generate) => {
  return axiosPublic.post(`${BASE_URL}/security-code`, { pirId, generate })
}

export const postVerifySecurityCode = (pirId, code) => {
  return axiosPublic.post(`${BASE_URL}/verify-security-code`, { pirId, code })
}

export const postLogin = (email, password) => {
  return axiosPublic.post(`${BASE_URL}/user/login`, { email, password })
}

export const postResetPassword = email => {
  return axiosPublic.post(`${BASE_URL}/user/reset`, { email })
}

export const postUpdatePassword = (token, password) => {
  return axiosPublic.post(`${BASE_URL}/user/update`, { token, password })
}
