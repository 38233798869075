export const citadelTheme = {
  colors: {
    primary: 'rgba(39, 149, 150, 1)',
    primaryLight: 'rgba(190, 223, 223, 1)',
    error: '#E84C3D',
    errorLight: 'rgba(232, 76, 61, 0.15)',
    white: '#FFFFFF',
    sectionDivider: '#CCCCCC',
    text: {
      body: '#333333',
      heading: '#333333',
      link: 'rgba(39, 149, 150, 1)',
      linkDisabled: 'rgba(39, 149, 150, 0.6)'
    },
    primaryButton: {
      border: 'rgba(39, 149, 150, 1)',
      background: 'rgba(39, 149, 150, 1)',
      hover: 'rgba(39, 149, 150, 0.8)',
      disabled: 'rgba(39, 149, 150, 0.6)',
      text: '#FFFFFF',
      disabledText: '#FFFFFF'
    },
    secondaryButton: {
      border: 'rgb(51, 51, 51)',
      hoverBorder: 'rgb(51, 51, 51, 0.4)',
      background: '#FFFFFF',
      hover: '#EEEEEE',
      disabled: '#F7F7F7',
      text: 'rgba(51, 51, 51, 1)',
      disabledText: 'rgba(51, 51, 51, 0.8)'
    },
    inputField: {
      background: '#EEEEEE',
      border: '#3A3A3A',
      focusedBorder: 'rgba(39, 149, 150, 1)',
      text: '#333333',
      placeholder: '#A6A6A6'
    },
    background: {
      white: '#FFFFFF',
      light: '#F7F7F7',
      medium: '#EEEEEE',
      pageOverlay: 'rgba(85, 85, 85, 0.7)'
    }
  },
  borderRadius: {
    button: '20px',
    iconButton: '5px',
    div: '20px',
    input: '5px'
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif'
  }
}
