import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'

const LogoutPage = () => {
  const { setAuthState } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    setAuthState({ accessToken: null })
    localStorage.removeItem('accessToken')
    navigate('/login', { replace: true })
  }, [])

  return <></>
}

export default LogoutPage
