import React from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { theme } from '../../themes/theme'
import { Page } from '../styles/Containers'

const Spinner = styled(CircularProgress)`
  color: ${theme.colors.primary};
`

const Loading = () => (
  <Page>
    <Spinner size={70} />
  </Page>
)

export default Loading
