import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ErrorIcon from '@mui/icons-material/Error'
import { theme } from '../../themes/theme'
import { SmallBodyText } from '../styles/Text'

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  margin: 0;

  border-radius: ${theme.borderRadius.div};
  background: ${theme.colors.errorLight};

  .MuiSvgIcon-root {
    color: ${theme.colors.error};
    width: 18px;
    height: 18px;
  }
`

const ErrorMessage = ({ children }) => (
  <ErrorContainer role="alert">
    <ErrorIcon size={15} />
    <SmallBodyText>{children ?? 'Hm, an error occurred. Try again.'}</SmallBodyText>
  </ErrorContainer>
)

ErrorMessage.propTypes = {
  children: PropTypes.node
}

export default ErrorMessage
