import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { format } from 'date-fns'

import DownloadIcon from '@mui/icons-material/Download'

import { theme } from '../../themes/theme'
import { getFileSize } from '../../util/strings'
import { handleDownloadResponse } from '../../util/downloadFile'
import { getPIRFileDownload } from '../../dataClient/apiCalls'
import { IconButton } from '../common/Button'
import { ErrorText, MedBodyText } from '../styles/Text'
import DeleteFileButton from './DeleteFileButton'

const Row = styled.div`
  display: flex;
  gap: 15px;
`

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 15px 15px;

  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  :hover {
    background: ${props => (props.clickable ? `${theme.colors.primaryLight}` : 'none')};
    border-radius: ${theme.borderRadius.div};
  }

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`

const FileNameContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  gap: 15px;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const Text = styled(MedBodyText)`
  font-weight: 500;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    width: 100%;
    text-wrap: wrap;
    word-break: break-word;
    line-height: 20px;
  }
`

const SizeDateText = styled(Text)`
  width: 20%;

  @media only screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
  }
`

const ButtonColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50px;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const MiniErrorText = styled(ErrorText)`
  font-size: 10px;
  text-align: right;
`

function File({ file, pirId, onDelete = () => {}, buttonAction = 'delete', fileClickAction = 'open' }) {
  const [downloadError, setDownloadError] = useState(false)

  async function handleClick(name, pirId, action) {
    try {
      const response = await getPIRFileDownload(pirId, name)
      handleDownloadResponse(response, name, action)
    } catch (e) {
      setDownloadError(true)
      console.error('An error occurred while fetching the document', name, e)
    }
  }

  return (
    <Row>
      <FileInfo clickable onClick={() => handleClick(file.name, pirId, fileClickAction)}>
        <FileNameContainer>
          <Text>{file.name}</Text>
        </FileNameContainer>
        <SizeDateText>{getFileSize(file.size)}</SizeDateText>
        <SizeDateText>{format(new Date(file.lastModified), 'MMM dd, yyyy')}</SizeDateText>
      </FileInfo>
      <ButtonColumn>
        {buttonAction === 'delete' && <DeleteFileButton pirId={pirId} name={file.name} onDelete={onDelete} />}
        {buttonAction === 'download' && (
          <ErrorContainer>
            <IconButton aria-label="Download file" onClick={() => handleClick(file.name, pirId, 'download')}>
              <DownloadIcon />
            </IconButton>
            {downloadError && <MiniErrorText role="alert">Error, try again.</MiniErrorText>}
          </ErrorContainer>
        )}
      </ButtonColumn>
    </Row>
  )
}

File.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired
  }).isRequired,
  pirId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  fileClickAction: PropTypes.oneOf(['open', 'download']),
  buttonAction: PropTypes.oneOf(['delete', 'download'])
}

export default File
