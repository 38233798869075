import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { logo } from '../../images/images.js'

import { FlexColumn, Divider, MedBGContainer, SpaceBetweenRowContainer } from '../styles/Containers.js'
import { BoldBodyText, SmallBodyText } from '../styles/Text'
import DetailSection from '../pirResponse/DetailSection.js'

const FormContainer = styled(MedBGContainer)`
  display: flex;
  align-items: ${props => (!props.details ? 'center' : 'flex-start')};
  gap: 30px;
  padding: 30px;
  text-align: left;

  @media only screen and (max-width: 1000px) {
    padding: 25px;
  }
`

const TotalContainer = styled(SpaceBetweenRowContainer)`
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`

const Total = styled(BoldBodyText)`
  font-size: 25px;
`

const Logo = styled.img`
  width: 80px;
  max-height: 80px;
`

const SmallText = styled.span`
  font-size: 12px;
`

function OrderSummary({ displayDetails = true, details = {}, cost = 47.25 }) {
  const formattedCost = (Math.round(cost * 100) / 100).toFixed(2)

  return (
    <FlexColumn width="100%" gap="15px">
      <FormContainer details={displayDetails}>
        <Logo src={logo} />
        <FlexColumn width="100%">
          <SpaceBetweenRowContainer>
            <FlexColumn gap="5px">
              <BoldBodyText>Property Information Request</BoldBodyText>
              <SmallBodyText>Document Access</SmallBodyText>
            </FlexColumn>
            <BoldBodyText>${formattedCost}</BoldBodyText>
          </SpaceBetweenRowContainer>
          {displayDetails && (
            <>
              <Divider />
              <DetailSection details={details} />
            </>
          )}
        </FlexColumn>
      </FormContainer>
      <Divider />
      <TotalContainer gap="10px 15px">
        <BoldBodyText>Total due today</BoldBodyText>
        <Total>
          ${formattedCost} CAD <SmallText>(incl. tax)</SmallText>
        </Total>
      </TotalContainer>
    </FlexColumn>
  )
}

OrderSummary.propTypes = {
  displayDetails: PropTypes.bool,
  details: PropTypes.object.isRequired,
  cost: PropTypes.number
}
export default OrderSummary
