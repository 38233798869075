import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext, Controller } from 'react-hook-form'
import { InputWrapperDiv } from '../styles/Containers'
import { TextField } from '../styles/TextField'

const getError = (id, errors) => {
  return id.split('.').reduce((o, i) => {
    const exists = o[i] !== undefined
    if (!exists) return {}
    return o[i]
  }, errors)
}

function FormattedInput({ required = false, id, label, handleFormatValue, disabled = false }) {
  const { register, formState, control } = useFormContext()

  const errors = formState.errors
  const error = getError(id, errors)

  return (
    <InputWrapperDiv>
      <Controller
        id={id}
        name={id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            refs={{ ...register(id) }}
            onChange={e => {
              const value = handleFormatValue(e.target.value)
              onChange(value)
            }}
            value={value}
            variant="standard"
            label={label}
            InputLabelProps={{
              shrink: true,
              required,
              focused: false
            }}
            InputProps={{
              disableUnderline: true
            }}
            disabled={disabled}
            error={Object.keys(error).length > 0 && !!error}
            helperText={error?.message}
            FormHelperTextProps={{ role: 'alert' }}
          />
        )}
      />
    </InputWrapperDiv>
  )
}

FormattedInput.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleFormatValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default FormattedInput
