import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const AuthContext = createContext()

const AuthProvider = ({ defaultState = {}, children }) => {
  const accessToken = defaultState.accessToken || localStorage.getItem('accessToken')

  const [authState, setAuthState] = useState({
    accessToken
  })

  const contextValue = useMemo(
    () => ({
      authState,
      setAuthState
    }),
    [authState]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

AuthProvider.propTypes = {
  defaultState: PropTypes.object,
  children: PropTypes.element.isRequired
}

export default AuthProvider
