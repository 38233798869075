import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getPIRFileDownload } from '../../dataClient/apiCalls'
import { downloadZipFile, getFile } from '../../util/downloadFile'
import Button from '../common/Button'
import ConfirmationTemplate from '../common/ConfirmationTemplate'
import ErrorMessage from '../common/ErrorMessage'
import Loading from '../common/Loading'

const bodyText = [
  'Thank you! Your Property Information Request response has been successfully emailed to the requestor.',
  'Download a copy of your response below.'
]

function PIRResponseConfirmation({ pirId, secondaryId, fileList }) {
  const [downloadError, setDownloadError] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)

  async function handleDownload() {
    setDownloadError(false)
    setDownloadLoading(true)
    const files = []
    let fileError = false

    for (const f of fileList) {
      try {
        const response = await getPIRFileDownload(pirId, f.name)
        const file = getFile(response, f.name)
        files.push(file)
      } catch (e) {
        fileError = true
        console.error(`an error occurred while downloading the file %s for PIR with ID`, f.name, pirId, e)
      }
    }

    if (!fileError) downloadZipFile(files, `${secondaryId}.zip`)
    else setDownloadError(true)

    setDownloadLoading(false)
  }

  if (!fileList) return <Loading />

  return (
    <ConfirmationTemplate title="Response successfully sent" bodyText={bodyText}>
      <Button onClick={handleDownload} loading={downloadLoading}>
        Download
      </Button>
      {downloadError && <ErrorMessage>There was an error downloading the files. Please try again.</ErrorMessage>}
    </ConfirmationTemplate>
  )
}

PIRResponseConfirmation.propTypes = {
  pirId: PropTypes.string.isRequired,
  secondaryId: PropTypes.string.isRequired,
  fileList: PropTypes.array.isRequired
}

export default PIRResponseConfirmation
