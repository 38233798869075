import styled from '@emotion/styled'
import { theme } from '../../themes/theme'

const Header = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`

const LargeTitle = styled.h1`
  text-align: center;
  font-size: 50px;
  line-height: 150%;
  margin: 0;
  font-weight: 400;
`

const PageTitle = styled.h1`
  font-size: 26px;
  font-weight: 700;
  margin: 0;
`

const Subheading = styled.h2`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
`

const FormTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`

const BodyText = styled.p`
  font-size: 16px;
  margin: 0;
`

const BoldBodyText = styled(BodyText)`
  font-weight: 500;
`

const SmallBodyText = styled(BodyText)`
  font-size: 12px;
`

const BoldSmallBodyText = styled(SmallBodyText)`
  font-weight: 500;
`

const MedBodyText = styled(BodyText)`
  font-size: 14px;
`

const BoldMedBodyText = styled(MedBodyText)`
  font-weight: 500;
`

const ErrorText = styled(SmallBodyText)`
  color: ${theme.colors.error};
  margin: 5px 0 0;
`

const LinkText = styled.a`
  color: ${theme.colors.text.link};
  :hover {
    color: ${theme.colors.text.linkDisabled};
  }
`

export default BodyText
export {
  Header,
  LargeTitle,
  PageTitle,
  Subheading,
  FormTitle,
  BoldBodyText,
  SmallBodyText,
  BoldSmallBodyText,
  MedBodyText,
  BoldMedBodyText,
  ErrorText,
  LinkText
}
