import MuiTextField from '@mui/material/TextField'
import styled from '@emotion/styled'
import { theme } from '../../themes/theme'

const inputField = theme.colors.inputField

const TextField = styled(MuiTextField)`
  .MuiInput-root {
    margin-top: 26px;
  }

  .MuiInputLabel-asterisk {
    color: ${theme.colors.error} !important;
  }

  .MuiFormHelperText-root {
    padding-top: 5px;
    font-size: 12px;
    color: ${theme.colors.error} !important;
    font-family: ${theme.fonts.body};
    letter-spacing: 0;
  }

  .MuiInputLabel-root {
    font-family: ${theme.fonts.body};
    font-size: 19px;
    color: ${inputField.text};
  }

  .MuiInputBase-root.Mui-disabled {
    border: 1px solid ${inputField.background};
  }

  .MuiInputBase-root {
    color: ${inputField.text};
    font-size: 14px;
    font-family: ${theme.fonts.body};
    font-weight: 400;
    line-height: 18px;

    background: ${inputField.background};
    border: ${({ error }) => (error ? `1px solid ${theme.colors.error} !important` : `1px solid ${inputField.border}`)};
    border-radius: ${theme.borderRadius.input};

    .MuiInputBase-input {
      padding: 8px 15px;
    }

    .MuiIconButton-root {
      padding: 8px;
    }
  }

  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      padding: 0;
      color: ${inputField.text};
      font-size: 18px;
    }
  }

  .Mui-focused {
    border: 1px solid ${inputField.focusedBorder};
  }

  .MuiFormHelperText-root.Mui-focused {
    border: none;
  }

  .MuiSelect-icon {
    padding-right: 8px;
  }

  .MuiSelect-iconOpen {
    padding-left: 8px;
  }

  .MuiSelect-select.MuiInputBase-input.MuiInput-input {
    padding-right: 35px;
  }
`

export { TextField }
