import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'

import { SmallBodyText, BoldSmallBodyText, LinkText } from '../styles/Text'
import { FlexColumn } from '../styles/Containers'

const Container = styled(FlexColumn)`
  gap: 10px;

  svg {
    width: 19px;
    height: 19px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const SupportInfo = ({ text, email, phone }) => (
  <Container>
    <BoldSmallBodyText>{text}</BoldSmallBodyText>
    <Item>
      <EmailIcon />
      <SmallBodyText>
        <LinkText href={`mailto:${email}?subject=In App Support`} target="__blank">
          {email}
        </LinkText>
      </SmallBodyText>
    </Item>
    <Item>
      <PhoneIcon />
      <SmallBodyText>{phone}</SmallBodyText>
    </Item>
  </Container>
)

SupportInfo.propTypes = {
  text: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
}

export default SupportInfo
