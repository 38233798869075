import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useParams, Link as ReactLink } from 'react-router-dom'

import { formatPIRForOrderSummary } from '../../util/pirFormatting'
import { getPaymentIntentSecret, getPIRById } from '../../dataClient/apiCalls'
import { CenteredFlexColumn, PageColumn, CenteredRowContainerReverse, LightBGContainer, FlexColumn } from '../styles/Containers'
import BodyText, { PageTitle, SmallBodyText } from '../styles/Text'
import OrderSummary from './OrderSummary'
import PaymentForm from './PaymentForm'
import Carousel from '../common/Carousel'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import Button, { SecondaryButton } from '../common/Button'
import PaymentConfirmation from './PaymentConfirmation'
import Link from '../styles/Link'

const InfoWrapper = styled(CenteredFlexColumn)`
  width: 40%;

  @media only screen and (max-width: 1000px) {
    width: 80%;
  }
`

const CardWrapper = styled(LightBGContainer)`
  padding: 40px;
  height: max-content;
  width: ${props => (props.details ? '720px' : '30%')};
  max-width: ${props => (props.details ? '800px' : 'none')};

  @media only screen and (max-width: 1500px) {
    width: ${props => (props.details ? '720px' : '40%')};
  }

  @media only screen and (max-width: 1000px) {
    padding: 25px;
    width: 90%;
  }
`

function PaymentPage() {
  const { pirId } = useParams()

  const [clientSecret, setClientSecret] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('0')
  const [activeStep, setActiveStep] = useState(0)
  const [pirDetails, setPirDetails] = useState()
  const [display, setDisplay] = useState('carousel')

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  const [isComplete, setIsComplete] = useState()

  const displayDetails = activeStep === 0

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)

  function handleClickStep(step) {
    if (step !== activeStep) setActiveStep(step)
  }

  const handlePayment = () => setDisplay('confirmation')

  useEffect(() => {
    function getPirDetails(pirId) {
      getPIRById(pirId)
        .then(({ data }) => {
          if (data.data.status !== 'NEW') {
            console.error('PIR already paid', pirId)
            setIsComplete(true)
          }
          setPirDetails(data.data)
          setLoading(false)
        })
        .catch(e => {
          console.error('failed to retrieve data for PIR', pirId, e)
          if (e.response && e.response.data.message === `pirId not found: ${pirId}`) {
            setErrorMessage(['The provided Property Information Request does not exist.'])
          } else {
            setErrorMessage([])
          }
          setLoading(false)
        })
    }

    if (pirId) getPirDetails(pirId)
  }, [pirId])

  useEffect(() => {
    function getPaymentIntent(pirId) {
      setLoading(true)
      getPaymentIntentSecret(pirId)
        .then(({ data }) => {
          setClientSecret(data.clientSecret)
          setPaymentAmount(data.amount)
          setLoading(false)
        })
        .catch(e => {
          console.error('failed to fetch payment intent secret', e)
          setErrorMessage(['Error getting payment details.', 'Please refresh the page to try again.'])
          setLoading(false)
        })
    }

    if (pirDetails && !isComplete) getPaymentIntent(pirId)
  }, [pirDetails, isComplete])

  if (loading) return <Loading />

  if (errorMessage !== undefined) {
    return <LoadingError message={errorMessage} />
  }

  if (isComplete) {
    return (
      <LoadingError message={['This Property Information Request has already been paid for.']}>
        <Button>
          <ReactLink to={`/pir/${pirId}/download`} target="_blank">
            Check PIR Status
          </ReactLink>
        </Button>
        <SmallBodyText>
          <Link to="/">Create a new PIR</Link>
        </SmallBodyText>
      </LoadingError>
    )
  }

  if (display === 'confirmation') {
    return <PaymentConfirmation />
  }

  return (
    <PageColumn>
      <CenteredFlexColumn gap="40px" width="100%">
        {displayDetails ? (
          <InfoWrapper gap="10px">
            <PageTitle>Request Details</PageTitle>
            <BodyText>
              Thank you for entering your Property Information Request details! Please review and confirm the information below before proceeding to
              payment.
            </BodyText>
          </InfoWrapper>
        ) : (
          <InfoWrapper gap="10px">
            <PageTitle>Payment Information</PageTitle>
            <BodyText>Please enter your payment details below to complete your Property Information Request submission.</BodyText>
          </InfoWrapper>
        )}
        <Carousel steps={['Request Details', 'Payment']} activeStep={activeStep} onClickStep={handleClickStep} />
        <CenteredRowContainerReverse width="95%">
          {!displayDetails && clientSecret && (
            <CardWrapper>
              <PaymentForm clientSecret={clientSecret} onSuccess={handlePayment} />
            </CardWrapper>
          )}
          <CardWrapper details={displayDetails}>
            <FlexColumn gap="15px">
              {pirDetails && <OrderSummary details={formatPIRForOrderSummary(pirDetails)} displayDetails={displayDetails} amount={paymentAmount} />}
              {pirDetails && displayDetails && (
                <SecondaryButton width="100%" onClick={() => handleNext()}>
                  Confirm & Proceed to Payment
                </SecondaryButton>
              )}
            </FlexColumn>
          </CardWrapper>
        </CenteredRowContainerReverse>
      </CenteredFlexColumn>
    </PageColumn>
  )
}

PaymentPage.propTypes = {
  pirDetails: PropTypes.object
}

export default PaymentPage
