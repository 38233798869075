import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { theme } from '../../themes/theme.js'
import { getPIRFileDownload } from '../../dataClient/apiCalls'
import { getFile, downloadZipFile } from '../../util/downloadFile'
import { FlexEndButtonErrorContainer, FlexColumn } from '../styles/Containers.js'
import BodyText from '../styles/Text'
import Button from '../common/Button'
import FileList from '../files/FileList'
import ErrorMessage from '../common/ErrorMessage'

const FileListContainer = styled.div`
  background: ${theme.colors.background.medium};
  border-radius: ${theme.borderRadius.div};
  padding: 20px 30px 5px 15px;
`

function DownloadFileList({ fileList = [], pirId, secondaryId }) {
  const [downloadError, setDownloadError] = useState(false)

  async function downloadAll() {
    setDownloadError(false)
    const files = []
    let fileError = false

    for (const f of fileList) {
      try {
        const response = await getPIRFileDownload(pirId, f.name)
        const file = getFile(response, f.name)
        files.push(file)
      } catch (e) {
        fileError = true
        console.error(`an error occurred while downloading the file %s for PIR with ID %s`, f.name, pirId, e)
      }
    }

    if (!fileError) downloadZipFile(files, `${secondaryId}.zip`)
    else setDownloadError(true)
  }

  return (
    <FlexColumn>
      <BodyText>Your Property Information Request has been completed!</BodyText>
      <BodyText>Download your files below.</BodyText>
      <FileListContainer>
        <FileList files={fileList} pirId={pirId} buttonAction="download" />
      </FileListContainer>
      <FlexEndButtonErrorContainer>
        <Button onClick={downloadAll}>Download All</Button>
        {downloadError && <ErrorMessage>There was an error downloading the files. Please try again.</ErrorMessage>}
      </FlexEndButtonErrorContainer>
    </FlexColumn>
  )
}

DownloadFileList.propTypes = {
  fileList: PropTypes.array,
  pirId: PropTypes.string.isRequired,
  secondaryId: PropTypes.string.isRequired
}

export default DownloadFileList
