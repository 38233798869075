function formatPostalCode(input) {
  if (!input) return ''

  const preformattedValue = input.toUpperCase().replace(/ /g, '')

  const regex = /^[A-Z][0-9][A-Z][0-9][A-Z][0-9]|^[A-Z][0-9][A-Z][0-9][A-Z]|^[A-Z][0-9][A-Z][0-9]|^[A-Z][0-9][A-Z]|^[A-Z][0-9]|^[A-Z]/
  const match = preformattedValue.match(regex)

  if (match) {
    return match[0].length > 3 ? match[0].substring(0, 3) + ' ' + match[0].substring(3) : match[0]
  }

  return ''
}

function getFileSize(fileSizeInBytes) {
  if (fileSizeInBytes === null || fileSizeInBytes === undefined || isNaN(fileSizeInBytes)) {
    return '0 B'
  }

  if (fileSizeInBytes < 1024) {
    return fileSizeInBytes + ' B'
  }

  let i = -1
  const byteUnits = [' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB']
  let size = fileSizeInBytes

  while (size >= 1024 && i < byteUnits.length - 1) {
    i++
    size /= 1024
  }

  return size.toFixed(1) + byteUnits[i]
}

function getFileContentType(filename) {
  const regex = /(?:\.([^.]+))?$/
  const extension = regex.exec(filename)[1]

  const contentTypes = {
    pdf: 'application/pdf',
    txt: 'text/plain',
    bmp: 'image/bmp',
    gif: 'image/gif',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
    html: 'text/html',
    xml: 'text/xml',
    zip: 'application/zip'
  }

  return contentTypes[extension] || 'application/octet-stream'
}

export { formatPostalCode, getFileSize, getFileContentType }
