const THEME = process.env.REACT_APP_THEME || 'citadel'

const citadelConfig = {
  pir: {
    city: 'Kelowna',
    province: 'BC'
  }
}

const kelownaConfig = {
  pir: {
    city: 'Kelowna',
    province: 'BC'
  }
}

const configMapping = {
  kelowna: { ...kelownaConfig },
  citadel: { ...citadelConfig }
}

export const config = configMapping[THEME]
