import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { InputAdornment, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { account } from '../../images/images.js'
import { postLogin } from '../../dataClient/apiCalls'
import { useAuth } from '../../providers/AuthProvider'
import { CenteredForm, FullWidthButtonContainer } from '../styles/Containers'
import { Header, SmallBodyText } from '../styles/Text.js'
import Button, { TextButton } from '../common/Button'
import ErrorMessage from '../common/ErrorMessage'
import FormInput from '../inputFields/FormInput'

const Image = styled.img`
  width: 250px;
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`

const InfoText = styled(SmallBodyText)`
  white-space: nowrap;
`

const loginSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required')
})

function LoginForm({ onSuccess = () => {} }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { setAuthState } = useAuth()
  const navigate = useNavigate()

  const methods = useForm({
    resolver: yupResolver(loginSchema)
  })
  const { handleSubmit } = methods

  async function onSubmit(data) {
    setLoading(true)

    try {
      const response = await postLogin(data.email, data.password)
      localStorage.setItem('accessToken', response.data.accessToken)
      setAuthState({ accessToken: response.data.accessToken })

      setError(false)
      setLoading(false)

      onSuccess()
    } catch (e) {
      console.error('error logging in', e)
      setError(true)
      setLoading(false)
    }
  }

  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleMouseDownPassword = event => event.preventDefault()

  return (
    <FormProvider {...methods}>
      <CenteredForm onSubmit={handleSubmit(onSubmit)}>
        <Image src={account} />
        <Header>Log in to your account</Header>
        <FormInput required={true} id="email" label="Email" basis="100%" />
        <FormInput
          required={true}
          id="password"
          label="Password"
          basis="100%"
          props={{
            type: showPassword ? 'text' : 'password'
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={showPassword ? 'hide password' : 'show password'}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <FullWidthButtonContainer>
          <Button type="submit" loading={loading}>
            Log In
          </Button>
          {error && <ErrorMessage>Invalid email or password. Please try again.</ErrorMessage>}
          <LinkContainer>
            <InfoText>Forgot your password?</InfoText>
            <TextButton onClick={() => navigate('/resetpassword')}>Reset</TextButton>
          </LinkContainer>
        </FullWidthButtonContainer>
      </CenteredForm>
    </FormProvider>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func
}

export default LoginForm
