import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'

import { postResetPassword } from '../../dataClient/apiCalls'
import { CenteredForm, CenteredFlexColumn } from '../styles/Containers'
import BodyText, { Header } from '../styles/Text'
import Button from '../common/Button'
import FormInput from '../inputFields/FormInput'
import ErrorMessage from '../common/ErrorMessage'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }
`

const emailSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required')
})

function ForgotPasswordForm() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const methods = useForm({
    resolver: yupResolver(emailSchema)
  })
  const { handleSubmit } = methods

  async function onSubmit(data) {
    setLoading(true)

    try {
      await postResetPassword(data.email)

      setError(false)
      setLoading(false)
      setIsComplete(true)
    } catch (e) {
      console.error('error sending forgot password email', e)
      setError(true)
      setLoading(false)
    }
  }

  if (isComplete) {
    return (
      <CenteredFlexColumn>
        <Header>Forgot your password</Header>
        <BodyText>If your email is associated with an account, an email has been sent to you with password reset instructions.</BodyText>
      </CenteredFlexColumn>
    )
  }

  return (
    <FormProvider {...methods}>
      <CenteredForm onSubmit={handleSubmit(onSubmit)}>
        <Header>Forgot your password</Header>
        <BodyText>Please enter the email associated with your account to initiate the password reset process.</BodyText>
        <FormInput required={true} id="email" label="Email" basis="100%" />

        <ButtonContainer>
          <Button type="submit" loading={loading}>
            Reset
          </Button>
          {error && <ErrorMessage>Error sending reset email. Please try again.</ErrorMessage>}
        </ButtonContainer>
      </CenteredForm>
    </FormProvider>
  )
}

export default ForgotPasswordForm
