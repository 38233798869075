import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { checkMark } from '../../images/images.js'
import { Page, FullHeightCenteredFlexColumn, CenteredFlexColumn } from '../styles/Containers.js'
import BodyText, { LargeTitle } from '../styles/Text.js'

const CheckMark = styled.img`
  width: 180px;
  align-items: center;
`

const SubContentContainer = styled(CenteredFlexColumn)`
  gap: 15px;
`

function ConfirmationTemplate({ title, bodyText, children }) {
  return (
    <Page>
      <FullHeightCenteredFlexColumn>
        <CheckMark src={checkMark} />
        <LargeTitle>{title}</LargeTitle>
        <SubContentContainer>
          {bodyText.map((t, i) => (
            <BodyText key={i}>{t}</BodyText>
          ))}
          {children}
        </SubContentContainer>
      </FullHeightCenteredFlexColumn>
    </Page>
  )
}

ConfirmationTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.array,
  children: PropTypes.node
}

export default ConfirmationTemplate
