import React, { useRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { theme } from '../../themes/theme'

const { inputField, text } = theme.colors

const Container = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 600px) {
    gap: 2%;
  }
`

const StyledInput = styled.input`
  color: ${text.body};
  font-size: 35px;
  font-weight: 600;
  text-align: center;

  background: ${inputField.background};
  border: 0.775px solid ${inputField.border};
  border-radius: calc(${theme.borderRadius.input} * 2);
  height: 63px;
  width: 58px;

  :focus {
    border: 0.775px solid ${inputField.focusedBorder};
    outline: none;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
    height: calc(90vw / 7);
    max-height: 63px;
    width: 15%;
  }
`

function CodeInput({ code, onUpdateCode }) {
  const digits = code.length
  const codeBoxReference = useRef([])

  function updateCode(value, index) {
    const newArr = [...code]
    newArr[index] = value
    onUpdateCode(newArr)
  }

  function handleChange(value, index) {
    if (value !== '') {
      updateCode(value, index)

      if (value && index < digits - 1) {
        codeBoxReference.current[index + 1].focus()
      }
    }
  }

  function handleMoveWithKeys(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      updateCode('', index - 1)
      codeBoxReference.current[index - 1].focus()
    }
    if (e.key === 'Backspace' && e.target.value) {
      updateCode('', index)
      codeBoxReference.current[index].focus()
    }
    if (e.key === 'ArrowLeft' && index > 0) {
      codeBoxReference.current[index - 1].focus()
    }
    if ((e.key === 'Enter' || e.key === 'ArrowRight') && index < digits - 1) {
      codeBoxReference.current[index + 1].focus()
    }
  }

  function handlePaste(e, index) {
    const newArr = [...code]
    const pastedArray = e.clipboardData.getData('Text').split('')

    let pasteIndex = 0
    for (let i = index; i < 6; i++) {
      newArr[i] = pastedArray[pasteIndex]
      pasteIndex += 1
    }

    onUpdateCode(newArr)

    const focused = index + pastedArray.length < 6 ? index + pastedArray.length : 5
    codeBoxReference.current[focused].focus()

    e.preventDefault()
  }

  return (
    <Container>
      {code.map((char, index) => (
        <StyledInput
          key={index}
          value={char}
          maxLength={1}
          onChange={e => handleChange(e.target.value, index)}
          onKeyUp={e => handleMoveWithKeys(e, index)}
          onPaste={e => handlePaste(e, index)}
          ref={reference => (codeBoxReference.current[index] = reference)}
          role="input"
        />
      ))}
    </Container>
  )
}

CodeInput.propTypes = {
  code: PropTypes.array.isRequired,
  onUpdateCode: PropTypes.func.isRequired
}

export default CodeInput
