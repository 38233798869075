import React from 'react'
import styled from '@emotion/styled'
import LogoutIcon from '@mui/icons-material/Logout'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from '../providers/AuthProvider'
import { SecondaryButton } from '../components/common/Button'
import SupportPopper from '../components/common/SupportPopper'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: calc(100vw - 20px);
  padding: 10px;
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: calc(100vh - 56px);
  height: 100%;
`

const HelpContainer = styled.div`
  position: fixed;
  right: 28px;
  bottom: 28px;
`

const supportProps = {
  text: 'Reach out to us with any technical questions or issues:',
  email: 'support@vvctec.com',
  phone: '(250) 544-9578'
}

const TIMEOUT_INTERVAL = 1000 * 60 * 20

export const ProtectedRoute = () => {
  const { authState } = useAuth()
  const navigate = useNavigate()

  const loc = useLocation()

  if (!authState.accessToken) {
    return <>{location.replace('/login?dest=' + loc.pathname + loc.search)}</>
  }

  const onIdle = () => navigate('/logout', { replace: true })

  useIdleTimer({
    onIdle,
    timeout: TIMEOUT_INTERVAL,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ]
  })

  const onLogout = () => navigate('/logout', { replace: true })

  return (
    <Page>
      <ButtonContainer>
        <SecondaryButton onClick={onLogout}>
          <LogoutIcon />
          Log Out
        </SecondaryButton>
      </ButtonContainer>
      <Body>
        <Outlet />
        <HelpContainer>
          <SupportPopper {...supportProps} />
        </HelpContainer>
      </Body>
    </Page>
  )
}
