import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import Button from '@mui/material/Button'

import { theme } from '../../themes/theme'
import File from './File'

const HeadingsContainer = styled.div`
  display: flex;
  gap: 15px;
`

const Headings = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 15px;
`

const FileName = styled.div`
  width: 60%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const SizeDateHeading = styled.div`
  width: 20%;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

const ButtonColumn = styled.div`
  width: 50px;
`

const HeaderButton = styled(Button)`
  padding: 0;
  color: ${props => (props.active === 'true' ? `${theme.colors.primary}` : `${theme.colors.text.body}`)};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-transform: none;
  text-align: left;
  font-family: inherit;

  :hover {
    background: none;
  }

  svg {
    height: 20px;
    padding-top: 2px;
  }
`

function sortByDate(a, b) {
  if (a.lastModified < b.lastModified) return 1
  if (a.lastModified > b.lastModified) return -1
  return 0
}

function sortByFileSize(a, b) {
  return a.size - b.size
}

function sortByKey(key) {
  return function (a, b) {
    const aValue = (key === 'owner' ? a.createdBy.name : a[key]).toUpperCase()
    const bValue = (key === 'owner' ? b.createdBy.name : b[key]).toUpperCase()

    if (aValue < bValue) return -1
    if (aValue > bValue) return 1
    return 0
  }
}

function FileList({ files, pirId, onDelete = () => {}, buttonAction = 'delete' }) {
  const [sortedFiles, setSortedFiles] = useState(files)
  const [sortBy, setSortBy] = useState()
  const [sortAscending, setSortAscending] = useState(true)

  function sort(newSortBy) {
    if (newSortBy === sortBy) {
      setSortedFiles([...sortedFiles.reverse()])
      setSortAscending(!sortAscending)
    } else {
      setSortBy(newSortBy)
      setSortAscending(true)

      if (newSortBy === 'date') setSortedFiles(sortedFiles.sort(sortByDate))
      else if (newSortBy === 'size') setSortedFiles(sortedFiles.sort(sortByFileSize))
      else setSortedFiles(sortedFiles.sort(sortByKey(newSortBy)))
    }
  }

  useEffect(() => {
    let sortedFileList = files
    if (sortBy) {
      if (sortBy === 'date') {
        sortedFileList = files.sort(sortByDate)
      } else if (sortBy === 'size') {
        sortedFileList = files.sort(sortByFileSize)
      } else {
        sortedFileList = files.sort(sortByKey(sortBy))
      }
      if (!sortAscending) {
        sortedFileList = [...sortedFileList.reverse()]
      }
    }
    setSortedFiles(sortedFileList)
  }, [files])

  return (
    <div>
      <HeadingsContainer>
        <Headings>
          <FileName>
            <HeaderButton variant="text" onClick={() => sort('name')} active={(sortBy === 'name').toString()}>
              Name <UnfoldMoreIcon />
            </HeaderButton>
          </FileName>
          <SizeDateHeading>
            <HeaderButton variant="text" onClick={() => sort('size')} active={(sortBy === 'size').toString()}>
              File Size <UnfoldMoreIcon />
            </HeaderButton>
          </SizeDateHeading>
          <SizeDateHeading>
            <HeaderButton variant="text" onClick={() => sort('date')} active={(sortBy === 'date').toString()}>
              Last Modified <UnfoldMoreIcon />
            </HeaderButton>
          </SizeDateHeading>
        </Headings>
        <ButtonColumn />
      </HeadingsContainer>

      {sortedFiles.length > 0 &&
        sortedFiles.map((file, i) => (
          <div key={i} data-testid="table-row">
            <File file={file} pirId={pirId} onDelete={onDelete} buttonAction={buttonAction} />
          </div>
        ))}
    </div>
  )
}

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  pirId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  buttonAction: PropTypes.oneOf(['delete', 'download'])
}

export default FileList
