import React from 'react'
import PropTypes from 'prop-types'
import FormSelect from '../inputFields/FormSelect'
import FormInput from '../inputFields/FormInput'
import FormattedInput from '../inputFields/FormattedInput'
import { formatPostalCode } from '../../util/strings'
import { FlexColumn, FormRow } from '../styles/Containers'
import { FormTitle } from '../styles/Text'
import { config } from '../../config'

const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']

const PropertyForm = ({ loading = false }) => (
  <FlexColumn>
    <FormTitle>Property Information</FormTitle>
    <FormRow>
      <FormInput required={true} id="propertyInformation.address" label="Address" disabled={loading} />
      <FormInput id="propertyInformation.unit" label="Unit" disabled={loading} />
      <FormInput required={true} id="propertyInformation.city" label="City" disabled />
      <FormSelect
        required={true}
        id="propertyInformation.province"
        label="Province"
        options={provinces}
        defaultValue={config.pir.province}
        disabled
      />
      <FormattedInput
        required={true}
        id="propertyInformation.postalCode"
        label="Postal Code"
        handleFormatValue={formatPostalCode}
        disabled={loading}
      />
    </FormRow>
  </FlexColumn>
)

PropertyForm.propTypes = {
  loading: PropTypes.bool
}

export default PropertyForm
