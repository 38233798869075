import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL || 'localhost:3000'

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

const axiosPublic = axios.create()

export default axiosPublic
