import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { BoldBodyText, FormTitle } from '../styles/Text'
import { Divider, FlexColumn, LightBGContainer } from '../styles/Containers'
import DetailSection from './DetailSection'

const SectionContainer = styled(LightBGContainer)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
`

const PageTitleText = styled(FormTitle)`
  font-weight: 600;
`

function renderDetails(section, details, hasSubchildren) {
  return (
    <SectionContainer>
      <BoldBodyText>{section}</BoldBodyText>
      <Divider />
      {hasSubchildren ? (
        Object.keys(details).map(d => <DetailSection key={d} sectionLabel={d} details={details[d]} />)
      ) : (
        <DetailSection details={details} />
      )}
    </SectionContainer>
  )
}

function RequestDetails({ pirDetails = {} }) {
  const details = pirDetails

  return (
    <FlexColumn gap="15px">
      <PageTitleText>Request Details</PageTitleText>
      {renderDetails('Property Information', details.propertyInformation)}
      {renderDetails('Owner Information', details.ownerInformation)}
      {pirDetails.strataInformation && renderDetails('Strata Information', details.strataInformation, true)}
      {renderDetails('Requestor Information', details.requestorInformation)}
    </FlexColumn>
  )
}

RequestDetails.propTypes = {
  pirDetails: PropTypes.shape({
    ownerInformation: PropTypes.object.isRequired,
    propertyInformation: PropTypes.object.isRequired,
    requestorInformation: PropTypes.object.isRequired,
    strataInformation: PropTypes.object
  }).isRequired
}
export default RequestDetails
