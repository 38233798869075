import React from 'react'
import './App.css'
import AuthProvider from './providers/AuthProvider.js'
import { Routes } from './routes/Routes'
import GlobalStyling from './GlobalStyling.js'

function App() {
  return (
    <>
      <GlobalStyling />
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </>
  )
}

export default App
