import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'

import RadioGroup from '@mui/material/RadioGroup'

import FormInput from '../inputFields/FormInput'
import MaskedInput from '../inputFields/MaskedInput'
import { theme } from '../../themes/theme'
import { FormRow, FlexColumn } from '../styles/Containers'
import { RowFormControl, StyledFormControlLabel, StyledRadio } from '../styles/RadioGroup'
import BodyText, { FormTitle, ErrorText } from '../styles/Text'

const Red = styled.span`
  color: ${theme.colors.error};
`

function RequestorForm({ loading = false }) {
  const { register, formState } = useFormContext()

  const error = !!formState.errors.requestorInformation?.isRealtor
  const helperText = formState.errors?.requestorInformation?.isRealtor?.message

  return (
    <FlexColumn>
      <FormTitle>Requestor Information</FormTitle>
      <FlexColumn gap="0">
        <RowFormControl>
          <BodyText>
            Are you a realtor or working on behalf of a realtor?<Red> *</Red>
          </BodyText>
          <RadioGroup row name="is-realtor-radio-group" id="isRealtor">
            <StyledFormControlLabel
              {...register('requestorInformation.isRealtor')}
              value={true}
              control={<StyledRadio />}
              label="Yes"
              disabled={loading}
            />
            <StyledFormControlLabel
              {...register('requestorInformation.isRealtor')}
              value={false}
              control={<StyledRadio />}
              label="No"
              disabled={loading}
            />
          </RadioGroup>
        </RowFormControl>
        {error && <ErrorText role="alert">{helperText}</ErrorText>}
      </FlexColumn>

      <FormRow>
        <FormInput id="requestorInformation.brokerageName" label="Agency / Brokerage Name" disabled={loading} />
        <FormInput required={true} id="requestorInformation.firstName" label="First Name" disabled={loading} />
        <FormInput required={true} id="requestorInformation.lastName" label="Last Name" disabled={loading} />
        <FormInput required={true} id="requestorInformation.address" label="Address" disabled={loading} />
        <MaskedInput required={true} id="requestorInformation.phone" label="Phone" format="(###) ###-####" disabled={loading} />
        <FormInput required={true} id="requestorInformation.email" label="Email" disabled={loading} />
        <FormInput id="requestorInformation.additionalName" label="Additional Name" disabled={loading} />
      </FormRow>
    </FlexColumn>
  )
}

RequestorForm.propTypes = {
  loading: PropTypes.bool
}

export default RequestorForm
