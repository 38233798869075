import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { TextField } from '../styles/TextField'
import { useFormContext } from 'react-hook-form'

import { InputWrapperDiv } from '../styles/Containers'

const getError = (id, errors) => {
  return id.split('.').reduce((o, i) => {
    const exists = o[i] !== undefined
    if (!exists) return {}
    return o[i]
  }, errors)
}

function FormSelect({ required = false, id, label, options, defaultValue = '', disabled = false }) {
  const { register, formState } = useFormContext()

  const errors = formState.errors
  const error = getError(id, errors)

  return (
    <InputWrapperDiv>
      <TextField
        {...register(id)}
        select
        id={id}
        name={id}
        label={label}
        variant="standard"
        defaultValue={defaultValue}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
          required,
          focused: false
        }}
        InputProps={{
          disableUnderline: true
        }}
        error={Object.keys(error).length > 0 && !!error}
        helperText={error?.message}
        FormHelperTextProps={{ role: 'alert' }}>
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </InputWrapperDiv>
  )
}

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
}

export default FormSelect
