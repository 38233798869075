import React from 'react'
import styled from '@emotion/styled'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { logo } from '../../images/images.js'

import { theme } from '../../themes/theme.js'
import SupportInfo from '../common/SupportInfo'
import LoginForm from '../authentication/LoginForm'
import { FlexColumn, WhiteBGContainer } from '../styles/Containers.js'

const Content = styled.div`
  background: ${theme.colors.background.light};
  display: flex;
  gap: 20%;
  align-items: center;
  justify-content: space-evenly;
  padding: 70px 10%;
  min-width: calc(100vw - 20%);
`

const WelcomeContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
`

const SupportContainer = styled(WhiteBGContainer)`
  margin-top: 50px;
  padding: 30px;
  max-width: 220px;
`

const Logo = styled.img`
  height: 100px;
`

const Welcome = styled.h1`
  font-size: 60px;
  margin: 0;
  max-width: 350px;
`

const supportProps = {
  text: 'Forgot your email or having issues logging in? Contact us for help:',
  email: 'support@vvctec.com',
  phone: '(250) 544-9578'
}

function LoginPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const onSuccess = () => {
    const dest = searchParams.get('dest')
    navigate(dest)
  }

  return (
    <Content>
      <WelcomeContainer>
        <Logo src={logo}></Logo>
        <Welcome>Welcome back!</Welcome>
        <SupportContainer>
          <SupportInfo {...supportProps} />
        </SupportContainer>
      </WelcomeContainer>
      <WhiteBGContainer>
        <LoginForm onSuccess={onSuccess} />
      </WhiteBGContainer>
    </Content>
  )
}

export default LoginPage
