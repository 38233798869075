import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

import { logo } from '../../images/images.js'
import { getPIRFileList, getPIRStatus } from '../../dataClient/apiCalls'
import { PageWithTitle, Header, Logo, LightBGContainer, CenteredContent } from '../styles/Containers.js'
import { PageTitle } from '../styles/Text'
import LoadingError from '../common/LoadingError'
import SecurityCodeForm from '../authentication/SecurityCodeForm'
import Loading from '../common/Loading'
import DownloadFileList from '../pirResponse/DownloadFileList.js'

const Page = styled(PageWithTitle)`
  min-width: calc(100vw - 200px);

  @media only screen and (max-width: 1000px) {
    min-width: none;
    width: 100%;
  }
`

const Body = styled(CenteredContent)`
  min-height: calc(100vh - 290px);
`

const SecurityCodeContainer = styled(LightBGContainer)`
  max-width: 550px;
  width: 90%;

  @media only screen and (max-width: 1000px) {
    padding: 5% 5%;
  }
`

const ContentContainer = styled(SecurityCodeContainer)`
  width: 90%;
  max-width: 800px;
`

function PIRResponseDownloadPage() {
  const { pirId } = useParams()

  const [pirDetails, setPIRDetails] = useState()
  const [fileList, setFileList] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    function getPIR(pirId) {
      getPIRStatus(pirId)
        .then(({ data }) => setPIRDetails(data.data))
        .catch(e => {
          console.error(`failed to retrieve the PIR status`, pirId, e)

          if (e.response && e.response.data.message === `pirId not found: ${pirId}`) {
            setErrorMessage(['The provided Property Information Request does not exist.'])
          } else {
            setErrorMessage([])
          }
        })
    }

    if (pirId) getPIR(pirId)
  }, [pirId])

  useEffect(() => {
    function getFileList(pirId) {
      getPIRFileList(pirId)
        .then(({ data }) => setFileList(data.data))
        .catch(e => {
          console.error('failed to retrieve the file list for PIR', pirId, e)
          setErrorMessage([])
        })
    }

    if (pirId && isVerified) getFileList(pirId)
  }, [pirId, isVerified])

  if (errorMessage !== undefined) {
    return <LoadingError message={errorMessage} />
  }

  const handleVerification = () => setIsVerified(true)

  return (
    <Page>
      <Header>
        <Logo src={logo}></Logo>
        <PageTitle>Download {pirDetails ? pirDetails.secondaryId : ''} Response</PageTitle>
      </Header>
      <Body>
        {!pirDetails ? (
          <Loading />
        ) : (
          <>
            {!isVerified ? (
              <SecurityCodeContainer>
                <SecurityCodeForm
                  status={pirDetails.status}
                  email={pirDetails.requestorEmail}
                  pirId={pirId}
                  secondaryId={pirDetails.secondaryId}
                  onVerify={() => handleVerification()}
                />
              </SecurityCodeContainer>
            ) : (
              <ContentContainer>
                <DownloadFileList fileList={fileList} pirId={pirId} secondaryId={pirDetails.secondaryId} />
              </ContentContainer>
            )}
          </>
        )}
      </Body>
    </Page>
  )
}

export default PIRResponseDownloadPage
