import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { InputAdornment, IconButton } from '@mui/material'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { postUpdatePassword } from '../../dataClient/apiCalls'
import { CenteredForm, FullWidthButtonContainer } from '../styles/Containers'
import BodyText, { Header } from '../styles/Text'
import Button from '../common/Button'
import FormInput from '../inputFields/FormInput'
import ErrorMessage from '../common/ErrorMessage'
import { useNavigate } from 'react-router-dom'

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(15, 'Password must be at least 15 characters')
    .max(31, 'Password must be less than 32 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Your passwords do not match')
    .required('Confirm Password is required')
})

function ResetPasswordForm({ token }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const navigate = useNavigate()

  const methods = useForm({
    resolver: yupResolver(passwordSchema)
  })
  const { handleSubmit } = methods

  async function onSubmit(data) {
    setLoading(true)

    try {
      await postUpdatePassword(token, data.password)

      setError(false)
      setLoading(false)
      navigate('/login')
    } catch (e) {
      console.error('error resetting password', e)
      setError(true)
      setLoading(false)
    }
  }

  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show)
  const handleMouseDownPassword = event => event.preventDefault()

  return (
    <FormProvider {...methods}>
      <CenteredForm onSubmit={handleSubmit(onSubmit)}>
        <Header>Reset your password</Header>
        <BodyText>Please enter a new password.</BodyText>
        <FormInput
          required={true}
          id="password"
          label="Password"
          basis="100%"
          props={{
            type: showPassword ? 'text' : 'password'
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={showPassword ? 'hide password' : 'show password'}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <FormInput
          required={true}
          id="confirmPassword"
          label="Confirm Password"
          basis="100%"
          props={{
            type: showConfirmPassword ? 'text' : 'password'
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={showConfirmPassword ? 'hide confirm password' : 'show confirm password'}
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}>
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <FullWidthButtonContainer>
          <Button type="submit" loading={loading}>
            Reset Password
          </Button>
          {error && <ErrorMessage>Error resetting password. Please try again.</ErrorMessage>}
        </FullWidthButtonContainer>
      </CenteredForm>
    </FormProvider>
  )
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired
}

export default ResetPasswordForm
