import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useDropzone } from 'react-dropzone'
import { Paper } from '@mui/material'

import UploadFileIcon from '@mui/icons-material/UploadFile'

import { theme } from '../../themes/theme'
import { CenteredFlexColumn } from '../styles/Containers'
import BodyText, { FormTitle } from '../styles/Text'
import useAxiosPrivate from '../../dataClient/hooks/useAxiosPrivate'
import Button from '../common/Button'
import ErrorMessage from '../common/ErrorMessage'
import Loading from '../common/Loading'

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    border: ${({ error }) => (error ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.primary}`)};
    border-radius: ${theme.borderRadius.div};
    background: none;
    min-height: 120px;
  }

  .MuiSvgIcon-root {
    height: 28px;
    width: 28px;
    color: ${theme.colors.primary};
  }

  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px;
`

const LoadingContainer = styled(CenteredFlexColumn)`
  border: 0.775px solid ${theme.colors.primaryLight};
  border-radius: ${theme.borderRadius.div};
  min-height: 120px;
  padding: 30px;
`

const ErrorContainer = styled.div`
  margin-top: 15px;
`

function UploadFile({ pirId, onUpload = () => {} }) {
  const [errorFileNames, setErrorFileNames] = useState([])
  const [loading, setLoading] = useState()

  const axiosPrivate = useAxiosPrivate()

  const postUploadPIRFile = (id, filename) => {
    const formData = new FormData()
    formData.append('file', filename)

    return axiosPrivate.post(`/upload/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const onDrop = useCallback(acceptedFiles => {
    setErrorFileNames([])
    setLoading(true)

    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i]

      postUploadPIRFile(pirId, file)
        .then(({ data }) => {
          onUpload(file, data.etag)
          if (i === acceptedFiles.length - 1) setLoading(false)
        })
        .catch(e => {
          setErrorFileNames(errorFileNames => [...errorFileNames].concat([file.name]))
          console.error(`failed to upload the file %s for PIR with ID %s`, file.name, pirId, e)
          if (i === acceptedFiles.length - 1) setLoading(false)
        })
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true })
  const { ref, ...rootProps } = getRootProps()

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
        <FormTitle>Uploading...</FormTitle>
      </LoadingContainer>
    )
  }

  return (
    <>
      <StyledPaper {...rootProps} variant="outlined" ref={ref} error={errorFileNames.length > 0}>
        <input {...getInputProps()} id="file-upload" aria-label="Upload files" />
        <UploadFileIcon />

        {isDragActive ? (
          <>Drop your file here</>
        ) : (
          <>
            <BodyText>Drag and drop files to upload or</BodyText>
            <Button onClick={open}>Browse</Button>
          </>
        )}
      </StyledPaper>
      {errorFileNames.length > 0 && (
        <ErrorContainer>
          <ErrorMessage>There was an error uploading the following file(s): {errorFileNames.map(f => f).join(', ')}. Please try again.</ErrorMessage>
        </ErrorContainer>
      )}
    </>
  )
}

UploadFile.propTypes = {
  pirId: PropTypes.string.isRequired,
  onUpload: PropTypes.func
}

export default UploadFile
