import React from 'react'
import ConfirmationTemplate from '../common/ConfirmationTemplate'

const bodyText = [
  'Thank you! The Property Information Request has been successfully rejected. ',
  'A confirmation has been emailed to the requestor and a refund has been issued.'
]

function PIRRejectConfirmation() {
  return <ConfirmationTemplate title="PIR Rejected" bodyText={bodyText}></ConfirmationTemplate>
}

export default PIRRejectConfirmation
