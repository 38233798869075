import JSZip from 'jszip'
import { getFileContentType } from './strings'

export function getFile(downloadResponse, fileName) {
  try {
    if (downloadResponse.status !== 200) {
      return console.error(`An error occurred while fetching the document ${fileName}`)
    }

    const propertyOverlayBuffer = downloadResponse.data
    return { fileName, data: propertyOverlayBuffer }
  } catch (e) {
    console.error('Error fetching the document:', e)
  }
}

export function handleDownloadResponse(downloadResponse, fileName, action) {
  const file = getFile(downloadResponse, fileName)

  if (action === 'download') {
    createDownloadLinkInWindow(file.data, fileName)
  } else {
    openInNewTab(file.data, fileName)
  }
}

export async function downloadZipFile(files, folderName) {
  const zip = new JSZip()
  for (const f of files) zip.file(f.fileName, f.data)
  const folder = await zip.generateAsync({ type: 'blob' })
  createDownloadLinkInWindow(folder, folderName)
}

function createDownloadLinkInWindow(dataBlob, filename) {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(dataBlob)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function openInNewTab(data, fileName) {
  const dataBlob = new Blob([data], { type: getFileContentType(fileName) })

  const fileURL = URL.createObjectURL(dataBlob)
  window.open(fileURL)
}
