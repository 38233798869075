import { axiosPrivate } from '../dataClient'
import { useEffect } from 'react'
import { useAuth } from '../../providers/AuthProvider'

const useAxiosPrivate = () => {
  const { authState, setAuthState } = useAuth()
  const REFRESH_TOKEN_PATH = '/user/refresh'

  if (authState.accessToken) {
    axiosPrivate.defaults.headers.common['Authorization'] = `Bearer ${authState?.accessToken}`
  }

  useEffect(() => {
    const resInterceptor = axiosPrivate.interceptors.response.use(
      async response => {
        return response
      },
      async error => {
        const { response, config: originalRequest } = error

        if (response.status === 403 && response.data?.name === 'TokenExpiredError' && !originalRequest?.retry) {
          originalRequest.retry = true
          try {
            const refreshResponse = await axiosPrivate.get(REFRESH_TOKEN_PATH)
            const newToken = refreshResponse.data.accessToken

            localStorage.setItem('accessToken', newToken)
            setAuthState(prev => ({ ...prev, accessToken: newToken }))
            axiosPrivate.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`

            return axiosPrivate(originalRequest)
          } catch (e) {
            // TODO: This catch block should log the users out
            localStorage.removeItem('accessToken')
            setAuthState(prev => ({ ...prev, accessToken: null }))
            return Promise.reject(e)
          }
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.response.eject(resInterceptor)
    }
  }, [authState])

  return axiosPrivate
}

export default useAxiosPrivate
