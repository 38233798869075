import React, { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { config } from '../../config.js'
import { logo } from '../../images/images.js'

import { postPIRData } from '../../dataClient/apiCalls'
import { FormSectionDiv, DarkBGPaddedForm, FlexEndButtonErrorContainer, PageWithTitle, Logo, Header } from '../styles/Containers'
import Button from '../common/Button'
import BodyText, { PageTitle } from '../styles/Text'
import PropertyForm from '../forms/PropertyForm'
import OwnerForm from '../forms/OwnerForm'
import RequestorForm from '../forms/RequestorForm'
import StrataForm from '../forms/StrataForm'
import ErrorMessage from '../common/ErrorMessage'

const phoneRegex = /^\(?([0-9]{3})\)? ([0-9]{3})[-]?([0-9]{4})/
const postalCodeRegex = /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/

const strataSchema = yup.object().shape({
  role: yup.string().required('Role is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  address: yup.string().required('Address is required'),
  phone: yup.string().matches(phoneRegex, 'Phone must be valid').required('Phone is required'),
  email: yup.string().email('Email must be a valid email').required('Email is required'),
  additionalName: yup.string(),
  strataNumber: yup.string().required('Strata Number is required')
})

const pirSchema = yup.object().shape({
  propertyInformation: yup.object().shape({
    address: yup.string().required('Address is required'),
    unit: yup.string(),
    city: yup.string().required('City is required'),
    province: yup.string().required('Province is required'),
    postalCode: yup
      .string()
      .matches(postalCodeRegex, { message: 'Postal Code must be valid', excludeEmptyString: true })
      .required('Postal Code is required')
  }),
  ownerInformation: yup.object().shape({
    ownedBy: yup.string().required('You must indicate who owns this property'),
    companyName: yup.string().when('ownedBy', {
      is: 'company',
      then: () => yup.string().required('Company Name is required')
    }),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    address: yup.string().required('Address is required'),
    phone: yup.string().matches(phoneRegex, 'Phone must be valid').required('Phone is required'),
    email: yup.string().email('Email must be a valid email').required('Email is required')
  }),
  isStrata: yup.boolean().required('You must indicate if the property is part of a strata'),
  strataInformation: yup.object().when('isStrata', {
    is: true,
    then: () => strataSchema
  }),
  requestorInformation: yup.object().shape({
    isRealtor: yup.boolean().required('You must indicate if you are a realtor'),
    brokerageName: yup.string(),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    address: yup.string().required('Address is required'),
    phone: yup.string().matches(phoneRegex, 'Phone must be valid').required('Phone is required'),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    additionalName: yup.string()
  })
})

function PIRPage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const methods = useForm({
    resolver: yupResolver(pirSchema),
    defaultValues: { propertyInformation: { address: '', city: config.pir.city, province: config.pir.province, postalCode: '' } }
  })
  const { handleSubmit } = methods

  const onError = (errors, e) => console.error('Error', errors, e)

  async function onSubmit(data) {
    setLoading(true)
    setError()

    const payload = structuredClone(data)

    if (!data.isStrata) delete payload.strataInformation
    delete payload.isStrata
    delete payload.ownerInformation.ownedBy

    postPIRData(payload)
      .then(response => {
        navigate(`/payment/${response.data.pirId}`)
        setLoading(false)
      })
      .catch(e => {
        console.error('Failed posting PIR data to server', e)

        if (e.response && e.response.data.message === 'error verifying owner name') setError('owner')
        else if (e.response && e.response.data.message === 'address not found') setError('address')
        else setError('other')

        setLoading(false)
      })
  }

  return (
    <PageWithTitle>
      <Header>
        <Logo src={logo}></Logo>
        <PageTitle>Property Information Request</PageTitle>
      </Header>
      <FormProvider {...methods}>
        <DarkBGPaddedForm onSubmit={handleSubmit(onSubmit, onError)}>
          <BodyText>
            A property information request is a process to provide copies of City documents and plans from the Building & Permitting branch regarding
            a specific property or properties. The City does not guarantee the availability of information for all files. <br />
            <br />
            The City endeavours to provide accurate information for the property file search in a timely manner, and ensures the privacy of property
            owners is protected and copyright regulations are followed.
            <br />
            <br />
            The City of Kelowna does not warranty the accuracy or completeness of this information and no representations are being made by providing
            this copy. Reliance on this information will be solely at your own risk and not that of the City. This information has been provided
            subject to the Federal Copyright Act and in accordance with the Freedom of Information and Protection of Privacy Act.
          </BodyText>

          <FormSectionDiv error={error === 'address'}>
            <PropertyForm loading={loading} />
            {error === 'address' && <ErrorMessage>The entered address does not match our records. Please update your request.</ErrorMessage>}
          </FormSectionDiv>
          <FormSectionDiv error={error === 'owner'}>
            <OwnerForm loading={loading} />
            {error === 'owner' && <ErrorMessage>The entered owner does not match our records. Please update your request.</ErrorMessage>}
          </FormSectionDiv>
          <FormSectionDiv>
            <StrataForm loading={loading} />
          </FormSectionDiv>
          <FormSectionDiv>
            <RequestorForm loading={loading} />
          </FormSectionDiv>
          <FlexEndButtonErrorContainer>
            <Button type="submit" loading={loading} padding="large">
              Review & Pay →
            </Button>
            {error && (
              <ErrorMessage>
                {error === 'owner' || error === 'address'
                  ? `The entered ${error} does not match our records. Please update your request and try again.`
                  : 'An error occurred while submitting the PIR. Please try again.'}
              </ErrorMessage>
            )}
          </FlexEndButtonErrorContainer>
        </DarkBGPaddedForm>
      </FormProvider>
    </PageWithTitle>
  )
}

export default PIRPage
