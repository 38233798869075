import React from 'react'
import PropTypes from 'prop-types'

import { PatternFormat } from 'react-number-format'
import { useFormContext, Controller } from 'react-hook-form'
import { InputWrapperDiv } from '../styles/Containers'
import { TextField } from '../styles/TextField'

const getError = (id, errors) => {
  return id.split('.').reduce((o, i) => {
    const exists = o[i] !== undefined
    if (!exists) return {}
    return o[i]
  }, errors)
}

function MaskedInput({ required = false, id, label, format, disabled = false }) {
  const { register, formState, control } = useFormContext()

  const errors = formState.errors
  const error = getError(id, errors)

  return (
    <InputWrapperDiv>
      <Controller
        id={id}
        name={id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <PatternFormat
            refs={{ ...register(id) }}
            onChange={onChange}
            value={value}
            format={format}
            mask="_"
            customInput={TextField}
            variant="standard"
            label={label}
            InputLabelProps={{
              shrink: true,
              required,
              focused: false
            }}
            InputProps={{
              disableUnderline: true
            }}
            disabled={disabled}
            error={Object.keys(error).length > 0 && !!error}
            helperText={error?.message}
            FormHelperTextProps={{ role: 'alert' }}
          />
        )}
      />
    </InputWrapperDiv>
  )
}

MaskedInput.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default MaskedInput
