export const kelownaTheme = {
  colors: {
    primary: 'rgba(0, 115, 207, 1)',
    primaryLight: 'rgba(0, 115, 207, 0.4)',
    error: '#E84C3D',
    errorLight: 'rgba(232, 76, 61, 0.15)',
    white: '#FFFFFF',
    sectionDivider: '#CCCCCC',
    text: {
      body: '#333333',
      heading: '#333333',
      link: 'rgba(0, 115, 207, 1)',
      linkDisabled: 'rgba(0, 115, 207, 0.5)'
    },
    primaryButton: {
      border: 'rgba(0, 115, 207, 1)',
      background: 'rgba(0, 115, 207, 0.4)',
      hover: 'rgba(0, 115, 207, 0.8)',
      disabled: 'rgba(0, 115, 207, 0.2)',
      text: 'rgba(51, 51, 51, 1)',
      disabledText: 'rgba(51, 51, 51, 0.4)'
    },
    secondaryButton: {
      border: 'rgb(51, 51, 51)',
      hoverBorder: 'rgb(51, 51, 51, 0.4)',
      background: '#FFFFFF',
      hover: '#EEEEEE',
      disabled: '#F7F7F7',
      text: 'rgba(51, 51, 51, 1)',
      disabledText: 'rgba(51, 51, 51, 0.8)'
    },
    inputField: {
      background: '#EEEEEE',
      border: '#3A3A3A',
      focusedBorder: 'rgba(0, 115, 207, 1)',
      text: '#333333',
      placeholder: '#A6A6A6'
    },
    background: {
      white: '#FFFFFF',
      light: '#F7F7F7',
      medium: '#EEEEEE',
      pageOverlay: 'rgba(85, 85, 85, 0.7)'
    }
  },
  borderRadius: {
    button: '0',
    iconButton: '0',
    div: '0',
    input: '0'
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif'
  }
}
