import React from 'react'
import styled from '@emotion/styled'

import citadelLogo from '../../images/citadel/logo.svg'
import { citadelTheme } from '../../themes/citadelTheme'

import BodyText, { Subheading } from '../styles/Text'
import { CenteredFlexColumn } from '../styles/Containers'

const CenteredRow = styled(CenteredFlexColumn)`
  flex-direction: row;
  justify-content: center;
`

const Logo = styled.img`
  height: 35px;
`

const PrimaryBodyText = styled(BodyText)`
  color: ${citadelTheme.colors.primary};
`

const BoldText = styled.span`
  font-weight: 700;
`

const PoweredBy = () => (
  <CenteredRow width="100%">
    <Subheading>Powered By</Subheading>
    <Logo src={citadelLogo}></Logo>
    <PrimaryBodyText>
      <BoldText>VIVA</BoldText>CITADEL
    </PrimaryBodyText>
  </CenteredRow>
)

export default PoweredBy
