import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { InputWrapperDiv } from '../styles/Containers'
import { TextField } from '../styles/TextField'

const getError = (id, errors) => {
  return id.split('.').reduce((o, i) => {
    const exists = o[i] !== undefined
    if (!exists) return {}
    return o[i]
  }, errors)
}

const FormInput = ({ required = false, id, label, basis, props, inputProps, multiline = false, disabled = false }) => {
  const { register, formState } = useFormContext()

  const errors = formState.errors
  const error = getError(id, errors)

  return (
    <InputWrapperDiv basis={basis}>
      <TextField
        {...register(id)}
        id={id}
        name={id}
        label={label}
        variant="standard"
        InputLabelProps={{
          shrink: true,
          required,
          focused: false
        }}
        InputProps={{
          disableUnderline: true,
          ...inputProps
        }}
        disabled={disabled}
        multiline={multiline}
        rows={multiline ? 5 : 1}
        error={Object.keys(error).length > 0 && !!error}
        helperText={error?.message}
        FormHelperTextProps={{ role: 'alert' }}
        {...props}
      />
    </InputWrapperDiv>
  )
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  basis: PropTypes.string,
  props: PropTypes.object,
  inputProps: PropTypes.object,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool
}

export default FormInput
