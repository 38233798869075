import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'

import RadioGroup from '@mui/material/RadioGroup'

import FormInput from '../inputFields/FormInput'
import MaskedInput from '../inputFields/MaskedInput'
import { theme } from '../../themes/theme'
import { FlexColumn, FormRow } from '../styles/Containers'
import { RowFormControl, StyledFormControlLabel, StyledRadio } from '../styles/RadioGroup'
import BodyText, { Subheading, FormTitle } from '../styles/Text'

const Red = styled.span`
  color: ${theme.colors.error};
`

function OwnerForm({ loading = false }) {
  const { register, watch } = useFormContext()

  const ownedBy = watch('ownerInformation.ownedBy')

  return (
    <FlexColumn>
      <FormTitle>Owner Information</FormTitle>
      <FlexColumn gap="0">
        <RowFormControl>
          <BodyText>
            Who owns this property?<Red> *</Red>
          </BodyText>
          <RadioGroup row name="owned-by-radio-group" id="ownerInformation.ownedBy" defaultValue="person">
            <StyledFormControlLabel
              {...register('ownerInformation.ownedBy')}
              value="person"
              control={<StyledRadio />}
              label="Person"
              disabled={loading}
            />
            <StyledFormControlLabel
              {...register('ownerInformation.ownedBy')}
              value="company"
              control={<StyledRadio />}
              label="Company"
              disabled={loading}
            />
          </RadioGroup>
        </RowFormControl>
      </FlexColumn>
      {ownedBy === 'company' && (
        <FlexColumn gap="10px">
          <Subheading>Company Details</Subheading>
          <FormRow>
            <FormInput required={true} id="ownerInformation.companyName" label="Company Name" disabled={loading} />
          </FormRow>
        </FlexColumn>
      )}
      <FlexColumn gap="10px">
        {ownedBy === 'company' && <Subheading>Authorized Signer Contact Information</Subheading>}
        <FormRow>
          <FormInput required={true} id="ownerInformation.firstName" label="First Name" disabled={loading} />
          <FormInput required={true} id="ownerInformation.lastName" label="Last Name" disabled={loading} />
          <FormInput required={true} id="ownerInformation.address" label="Address" disabled={loading} />
          <MaskedInput required={true} id="ownerInformation.phone" label="Phone" format="(###) ###-####" disabled={loading} />
          <FormInput required={true} id="ownerInformation.email" label="Email" disabled={loading} />
        </FormRow>
      </FlexColumn>
    </FlexColumn>
  )
}

OwnerForm.propTypes = {
  loading: PropTypes.bool
}

export default OwnerForm
