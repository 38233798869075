import React from 'react'
import { Global } from '@emotion/react'
import { theme } from './themes/theme'

const GlobalStyling = () => (
  <Global
    styles={{
      body: {
        fontFamily: `${theme.fonts.body}`,
        color: `${theme.colors.text.body}`
      },
      p: {
        color: `${theme.colors.text.body}`
      },
      h1: {
        fontFamily: `${theme.fonts.heading}`,
        color: `${theme.colors.text.heading}`
      },
      h2: {
        fontFamily: `${theme.fonts.heading}`,
        color: `${theme.colors.text.heading}`
      },
      h3: {
        fontFamily: `${theme.fonts.heading}`,
        color: `${theme.colors.text.heading}`
      },
      input: {
        fontFamily: `${theme.fonts.body}`
      }
    }}
  />
)

export default GlobalStyling
