import { citadelTheme } from './citadelTheme'
import { kelownaTheme } from './kelownaTheme'

const THEME = process.env.REACT_APP_THEME || 'citadel'

const themeMapping = {
  kelowna: { ...kelownaTheme },
  citadel: { ...citadelTheme }
}

export const theme = themeMapping[THEME]
