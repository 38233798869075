import styled from '@emotion/styled'
import { Link as ReactLink } from 'react-router-dom'
import { theme } from '../../themes/theme'

const Link = styled(ReactLink)`
  color: ${theme.colors.text.link};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.text.linkDisabled};
  }
`

export default Link
