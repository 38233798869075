import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import styled from '@emotion/styled'
import React from 'react'
import CheckoutForm from './CheckoutForm'
import PropTypes from 'prop-types'
import { theme } from '../../themes/theme'
import { FlexColumn } from '../styles/Containers'
import BodyText from '../styles/Text'

const PaymentFormContainer = styled(FlexColumn)`
  gap: 15px;
  width: 100%;
  text-align: left;
`

const appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: `${theme.colors.text.body}`,
    colorBackground: `${theme.colors.background.white}`,
    colorText: `${theme.colors.text.body}`,
    colorDanger: `${theme.colors.error}`,
    fontFamily: `${theme.fonts.body}`,
    borderRadius: `${theme.borderRadius.input}`,
    spacingGridRow: '20px'
  },
  rules: {
    '.AccordionItem': {
      border: '0'
    },
    '.Input': {
      backgroundColor: `${theme.colors.inputField.background}`,
      border: `0.775px solid ${theme.colors.inputField.border}`
    },
    '.Input:focus': {
      border: `0.775px solid ${theme.colors.inputField.focusedBorder}`,
      boxShadow: 'none'
    },
    '.Input--invalid': {
      boxShadow: 'none'
    },
    '.Label': {
      fontSize: '14px',
      lineHeight: '14px',
      marginBottom: '8px'
    },
    '.Error': {
      fontSize: '12px',
      marginTop: '8px'
    }
  }
}

function PaymentForm({ clientSecret, onSuccess = () => {} }) {
  const stripeOptions = {
    clientSecret,
    appearance,
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap' }]
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

  return (
    clientSecret && (
      <PaymentFormContainer id="payment-form-cont">
        <BodyText>Payment Information</BodyText>
        {stripePromise && (
          <Elements options={stripeOptions} stripe={stripePromise}>
            <CheckoutForm onSuccess={onSuccess} />
          </Elements>
        )}
      </PaymentFormContainer>
    )
  )
}

PaymentForm.propTypes = {
  clientSecret: PropTypes.string,
  onSuccess: PropTypes.func
}

export default PaymentForm
