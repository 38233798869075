import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import { theme } from '../../themes/theme'

export const RowFormControl = styled(FormControl)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 40px;

  h1 {
    text-align: left;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 13px;
    color: ${theme.colors.text.body};
    font-family: ${theme.fonts.body};
  }

  .MuiRadio-root.Mui-checked {
    color: ${theme.colors.primary};
  }
`

export const StyledRadio = ({ size, ...props }) => <Radio {...props} size={size || 'small'} />
StyledRadio.propTypes = { size: PropTypes.string }
