import React from 'react'
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import { ProtectedRoute } from './ProtectedRoute'
import { UnprotectedRoute } from './UnprotectedRoute'
import PIRPage from '../components/pages/PIRPage'
import PaymentPage from '../components/payments/PaymentPage'
import PIRResponsePage from '../components/pages/PIRResponsePage'
import PIRResponseDownloadPage from '../components/pages/PIRResponseDownloadPage'
import NotFoundPage from '../components/pages/NotFoundPage'
import LoginPage from '../components/pages/LoginPage'
import LoggedInPage from '../components/pages/LoggedInPage'
import LogoutPage from '../components/pages/LogoutPage'
import ForgotPasswordPage from '../components/pages/ForgotPasswordPage'

export const Routes = () => {
  const { authState } = useAuth()

  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        { path: '/', element: <LoggedInPage /> },
        { path: '/login', element: <Navigate replace to="/" /> },
        { path: '/pir/:pirId', element: <PIRResponsePage /> },
        { path: '/logout', element: <LogoutPage /> },
        { path: '*', element: <NotFoundPage /> }
      ]
    }
  ]

  const routesForNotAuthenticatedOnly = [
    {
      path: '/',
      element: <UnprotectedRoute />,
      children: [
        { path: '/', element: <PIRPage /> },
        { path: '/login', element: <LoginPage /> },
        { path: '/resetpassword', element: <ForgotPasswordPage /> },
        { path: '/payment/:pirId', element: <PaymentPage /> },
        { path: '/pir/:pirId/download', element: <PIRResponseDownloadPage /> }
      ]
    }
  ]

  const router = createBrowserRouter([...(!authState.accessToken ? routesForNotAuthenticatedOnly : []), ...routesForAuthenticatedOnly])

  return <RouterProvider router={router} />
}
