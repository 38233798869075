import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

import { logo } from '../../images/images.js'

import useAxiosPrivate from '../../dataClient/hooks/useAxiosPrivate'
import { formatPIRDetailsForDisplay } from '../../util/pirFormatting.js'
import { getPIRFileList, getPIRById } from '../../dataClient/apiCalls'
import { PageWithTitle, HeaderWithButtons, Logo, MedBGContainer, FlexColumn, LightBGContainer } from '../styles/Containers.js'
import BodyText, { PageTitle } from '../styles/Text'
import Button from '../common/Button'
import ErrorMessage from '../common/ErrorMessage'
import FileList from '../files/FileList'
import LoadingError from '../common/LoadingError'
import UploadFile from '../files/UploadFile'
import ResponseEmail from '../pirResponse/ResponseEmail'
import RequestDetails from '../pirResponse/RequestDetails'
import Loading from '../common/Loading'
import RejectButton from '../pirResponse/RejectButton'
import PIRRejectConfirmation from '../pirResponse/PIRRejectConfirmation'
import PIRResponseConfirmation from '../pirResponse/PIRResponseConfirmation'

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
`

const ColumnHeaderGroup = styled(HeaderGroup)`
  flex-direction: column;
  align-items: flex-end;
  padding-top: 42px;
  margin-bottom: 15px;
`

const HeaderButtons = styled(ColumnHeaderGroup)`
  gap: 15px;
  flex-direction: row;
  padding-top: 0;
`

const StatusHeaderGroup = styled(ColumnHeaderGroup)`
  padding-top: 54px;
`

const ContentContainer = styled(LightBGContainer)`
  display: flex;
  gap: 50px;
  padding: 50px;

  @media only screen and (max-width: 1000px) {
    gap: 30px;
  }
`

const LeftColumn = styled(FlexColumn)`
  width: 70%;
`

const FileListContainer = styled(MedBGContainer)`
  padding: 20px 30px 5px 15px;
`

const RequestContainer = styled(MedBGContainer)`
  width: 30%;
  padding: 30px;
  height: max-content;
`

function PIRResponsePage() {
  const { pirId } = useParams()

  const [loadingList, setLoadingList] = useState(true)
  const [loadingDetails, setLoadingDetails] = useState(true)

  const [fileList, setFileList] = useState([])
  const [pirDetails, setPirDetails] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [submitLoading, setSubmitLoading] = useState()
  const [submitError, setSubmitError] = useState(false)
  const [display, setDisplay] = useState('response')

  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    function getFileList(pirId) {
      getPIRFileList(pirId)
        .then(({ data }) => {
          setFileList(data.data)
          setLoadingList(false)
        })
        .catch(e => {
          console.error('failed to retrieve the file list for PIR', pirId, e)

          if (e.response && e.response.data.message === `pirId not found: ${pirId}`) {
            setErrorMessage(['The provided Property Information Request does not exist.'])
          } else {
            setErrorMessage([])
          }
          setLoadingList(false)
        })
    }

    function getPIRDetails(pirId) {
      getPIRById(pirId)
        .then(({ data }) => {
          setPirDetails({ ...data.data, id: data.data.id.toString() })
          setLoadingDetails(false)
        })

        .catch(e => {
          console.error('failed to retrieve details for PIR', pirId, e)

          if (e.response && e.response.data.message === `pirId not found: ${pirId}`) {
            setErrorMessage(['The provided Property Information Request does not exist.'])
          } else {
            setErrorMessage([])
          }
          setLoadingDetails(false)
        })
    }

    if (pirId) {
      getFileList(pirId)
      getPIRDetails(pirId)
    }
  }, [pirId])

  if (errorMessage !== undefined) {
    return <LoadingError message={errorMessage} />
  }

  function handleDelete(fileName) {
    const newFileList = fileList.filter(f => f.name !== fileName)
    setFileList(newFileList)
  }

  function handleUpload(file, etag) {
    const formattedFile = {
      name: file.name,
      size: file.size,
      lastModified: new Date().toISOString(),
      etag
    }
    setFileList(fileList => [...fileList].filter(f => f.name !== file.name).concat([formattedFile]))
  }

  function handleSubmit() {
    if (fileList.length > 0) {
      setSubmitLoading(true)
      axiosPrivate
        .post(`/pir/${pirId}/response`)
        .then(() => {
          setSubmitLoading(false)
          setDisplay('responseConfirmation')
        })
        .catch(e => {
          console.error('failed to send response email', pirId, e)
          setSubmitError('serverError')
          setSubmitLoading(false)
        })
    } else {
      console.error('cannot submit a PIR with an empty response', pirId)
      setSubmitError('emptyError')
    }
  }

  const handleReject = () => setDisplay('rejectConfirmation')

  if (loadingList || loadingDetails) return <Loading />

  if (!['READY', 'COMPLETED', 'REJECTED', 'EXPIRED'].includes(pirDetails.status))
    return <LoadingError message={['This PIR is not ready for review yet.']} />

  const disableButtons = ['COMPLETED', 'REJECTED', 'EXPIRED'].includes(pirDetails.status)

  if (display === 'rejectConfirmation') {
    return <PIRRejectConfirmation />
  } else if (display === 'responseConfirmation') {
    return <PIRResponseConfirmation pirId={pirId} secondaryId={pirDetails.secondaryId} fileList={fileList} />
  }

  return (
    <PageWithTitle>
      <HeaderWithButtons>
        <HeaderGroup>
          <Logo src={logo} />
          <PageTitle>{pirDetails.secondaryId} Response</PageTitle>
        </HeaderGroup>
        {!disableButtons ? (
          <ColumnHeaderGroup>
            <HeaderButtons>
              <RejectButton pirId={pirId} onSuccess={handleReject} disabled={submitLoading} />
              <Button loading={submitLoading} onClick={handleSubmit}>
                Submit
              </Button>
            </HeaderButtons>
            {submitError && submitError === 'serverError' && <ErrorMessage />}
            {submitError && submitError === 'emptyError' && <ErrorMessage>A response must include at least one attachment.</ErrorMessage>}
          </ColumnHeaderGroup>
        ) : (
          <StatusHeaderGroup>PIR {pirDetails.status}</StatusHeaderGroup>
        )}
      </HeaderWithButtons>
      <ContentContainer>
        <LeftColumn>
          {pirDetails && (
            <FlexColumn gap="12px">
              <BodyText>The following email will be sent to the requestor when you submit your response:</BodyText>
              <MedBGContainer padding="30px">
                <ResponseEmail pirDetails={pirDetails} />
              </MedBGContainer>
            </FlexColumn>
          )}
          <FlexColumn gap="12px">
            <BodyText>The following files will be attached to your response:</BodyText>
            <FileListContainer>
              <FileList files={fileList} pirId={pirId} onDelete={handleDelete} buttonAction={disableButtons ? 'download' : 'delete'} />
            </FileListContainer>
          </FlexColumn>
          {!disableButtons && (
            <FlexColumn gap="12px">
              <BodyText>Drag and drop or browse below to add any additional files to the list:</BodyText>
              <UploadFile pirId={pirId} onUpload={handleUpload} />
            </FlexColumn>
          )}
        </LeftColumn>
        {pirDetails && (
          <RequestContainer>
            <RequestDetails pirDetails={formatPIRDetailsForDisplay(pirDetails)} />
          </RequestContainer>
        )}
      </ContentContainer>
    </PageWithTitle>
  )
}

export default PIRResponsePage
