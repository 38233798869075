import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useForm, FormProvider } from 'react-hook-form'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormTitle } from '../styles/Text'
import { FlexEndContainer, PaddedForm, StyledDialog, StyledDialogContent } from '../styles/Containers'
import Button, { SecondaryButton } from '../common/Button'
import FormSelect from '../inputFields/FormSelect'
import FormInput from '../inputFields/FormInput'
import ErrorMessage from '../common/ErrorMessage'
import useAxiosPrivate from '../../dataClient/hooks/useAxiosPrivate'

const reasons = [
  'Incorrect or missing information',
  'Requester not eligible',
  'Information not available on this property',
  'Cancellation requested by the PIR requestor',
  'Other'
]

const StyledFormInput = styled(FormInput)`
  .MuiInputBase-input {
    min-height: 400px;
  }
`

const rejectSchema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
  details: yup.string().when('reason', {
    is: 'Other',
    then: () => yup.string().required('Additional Details are required when reason is Other')
  })
})

function RejectButton({ pirId, onSuccess = () => {}, disabled = false }) {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const handleOpen = () => setIsVisible(true)
  const handleClose = () => {
    reset({ reason: reasons[2] })
    setError(false)
    setIsVisible(false)
    setIsLoading(false)
  }

  const methods = useForm({ resolver: yupResolver(rejectSchema) })
  const { handleSubmit, watch, reset, clearErrors } = methods
  const reason = watch('reason')

  const onError = (errors, e) => console.error('Error', errors, e)

  useEffect(() => {
    if (reason !== 'Other') clearErrors('details')
  }, [reason])

  async function onSubmit(data) {
    setIsLoading(true)
    setError(false)

    axiosPrivate
      .post(`/pir/${pirId}/reject`, { reason: data.reason, details: data.details })
      .then(() => {
        handleClose()
        onSuccess()
      })
      .catch(e => {
        console.error('Failed rejecting PIR', e)
        setError(true)
        setIsLoading(false)
      })
  }

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <div>
      <SecondaryButton disabled={disabled} onClick={handleOpen}>
        Reject
      </SecondaryButton>
      <StyledDialog fullWidth open={isVisible} onClose={handleClose} keepMounted={false}>
        <StyledDialogContent>
          <FormProvider {...methods}>
            <PaddedForm onSubmit={handleSubmit(onSubmit, onError)}>
              <FormTitle>Reject PIR</FormTitle>
              <FormSelect required={true} id="reason" label="Reason" options={reasons} defaultValue={reasons[2]} />
              <StyledFormInput required={reason === 'Other'} id="details" label="Additional Details" multiline={true} />

              {error && <ErrorMessage />}
              <FlexEndContainer>
                <SecondaryButton onClick={handleClose} disabled={isLoading}>
                  Cancel
                </SecondaryButton>
                <Button type="submit" loading={isLoading}>
                  Confirm Reject
                </Button>
              </FlexEndContainer>
            </PaddedForm>
          </FormProvider>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  )
}

RejectButton.propTypes = {
  pirId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool
}

export default RejectButton
