import kelownaAccount from './kelowna/account.svg'
import kelownaCheckMark from './kelowna/checkMark.svg'
import kelownaLock from './kelowna/lock.svg'
import kelownaLogo from './kelowna/logo.png'

import citadelAccount from './citadel/account.svg'
import citadelCheckMark from './citadel/checkMark.svg'
import citadelLock from './citadel/lock.svg'
import citadelLogo from './citadel/logo.svg'

const THEME = process.env.REACT_APP_THEME || 'citadel'

const accountMapping = {
  kelowna: kelownaAccount,
  citadel: citadelAccount
}

const checkMarkMapping = {
  kelowna: kelownaCheckMark,
  citadel: citadelCheckMark
}

const lockMapping = {
  kelowna: kelownaLock,
  citadel: citadelLock
}

const logoMapping = {
  kelowna: kelownaLogo,
  citadel: citadelLogo
}

export const account = accountMapping[THEME]
export const checkMark = checkMarkMapping[THEME]
export const lock = lockMapping[THEME]
export const logo = logoMapping[THEME]
