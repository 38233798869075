import React from 'react'
import PropTypes from 'prop-types'
import { CenteredFlexColumn } from '../styles/Containers'
import BodyText from '../styles/Text'

const LoadingError = ({ message, children }) => (
  <CenteredFlexColumn>
    {message && message.length > 0 ? (
      message.map((sentence, i) => <BodyText key={`loading-error-message-${i}`}>{sentence}</BodyText>)
    ) : (
      <>
        <BodyText>We&#39;re sorry.</BodyText>
        <BodyText>The data you requested could not be loaded.</BodyText>
      </>
    )}
    {children}
  </CenteredFlexColumn>
)

LoadingError.propTypes = {
  message: PropTypes.array,
  children: PropTypes.node
}

export default LoadingError
