import React from 'react'

import Link from '../styles/Link'
import BodyText from '../styles/Text'
import ConfirmationTemplate from '../common/ConfirmationTemplate'

const bodyText = [
  <>
    Your request has been created, and requests for digital signatures have been sent to the email addresses you provided.
    <b> Please ensure each digital signature is completed as early as possible.</b>
  </>,
  <>
    Once all signatures are received, your request will be entered into the queue. We aim to keep our service turnaround time of 1-3 Business days
    after signatures are received. Please check your email for updates and your receipt for this transaction.
  </>
]

const PaymentConfirmation = () => (
  <ConfirmationTemplate title="Payment complete" bodyText={bodyText}>
    <BodyText>
      Need to make another Property Information Request?&nbsp;
      <Link to="/">Get started</Link>
    </BodyText>
  </ConfirmationTemplate>
)

export default PaymentConfirmation
