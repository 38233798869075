import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'

import HelpCenterIcon from '@mui/icons-material/HelpCenter'

import { theme } from '../../themes/theme'
import SupportInfo from './SupportInfo'

const Container = styled.div`
  :hover {
    cursor: pointer;
  }

  svg {
    height: 50px;
    width: 50px;
    color: ${theme.colors.primary};
  }
`

const SupportContainer = styled(Box)`
  padding: 30px;
  max-width: 220px;
  margin-right: 7px;
  border-radius: ${theme.borderRadius.div} ${theme.borderRadius.div} ${theme.borderRadius.input};
  border: 1px solid ${theme.colors.primary};
  background: ${theme.colors.background.white};
`

function SupportPopper({ text, email, phone }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => setAnchorEl(anchorEl ? null : event.currentTarget)

  const open = Boolean(anchorEl)
  const id = open ? 'support-popper' : undefined

  return (
    <>
      <Container aria-describedby={id} aria-label="Support" role="button" onClick={handleClick}>
        <HelpCenterIcon />
      </Container>
      <Popper id={id} open={open} placement="top-end" transition anchorEl={anchorEl}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <SupportContainer>
              <SupportInfo text={text} email={email} phone={phone} />
            </SupportContainer>
          </Fade>
        )}
      </Popper>
    </>
  )
}

SupportPopper.propTypes = {
  text: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
}

export default SupportPopper
