import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { SmallBodyText } from '../styles/Text'
import { FlexColumn } from '../styles/Containers'

const List = styled.ol`
  margin: 0;
  font-size: 12px;
  line-height: 18px;
`

const Bold = styled.span`
  font-weight: 600;
`

const ResponseEmail = ({ pirDetails }) => (
  <FlexColumn gap="10px">
    <SmallBodyText>
      Dear {pirDetails.requestorInformation.firstName} {pirDetails.requestorInformation.lastName},
    </SmallBodyText>
    <SmallBodyText>
      This email is to inform you that your Property Information Request has been completed. Please find and download the files corresponding to this
      request via the following link: {process.env.REACT_APP_FRONT_END_URL}/pir/{pirDetails.id}/download
    </SmallBodyText>
    <SmallBodyText>
      <Bold>Important note:</Bold> your files will only be available for <Bold>30 days</Bold>, and will subsequently be removed from the portal.
      Please ensure you download these files promptly and save them for future reference.
    </SmallBodyText>
    <SmallBodyText>Steps:</SmallBodyText>
    <List>
      <li>
        <SmallBodyText>
          Click the link above to access your response files for download. A security code will be generated and sent to your email address. Please
          check your junk mail / spam folder for the security code.
        </SmallBodyText>
      </li>
      <li>
        <SmallBodyText>Enter the security code and click download.</SmallBodyText>
      </li>
      <li>
        <SmallBodyText>
          Save the downloaded files somewhere safe on your device for future reference. This link will be deactivated and files will be deleted after
          30 days.
        </SmallBodyText>
      </li>
    </List>
  </FlexColumn>
)

ResponseEmail.propTypes = {
  pirDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    requestorInformation: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default ResponseEmail
