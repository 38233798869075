import styled from '@emotion/styled'
import { Dialog, DialogContent } from '@mui/material'
import { theme } from '../../themes/theme'

// PAGE STYLING
export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`

export const PageColumn = styled(Page)`
  flex-direction: column;
  padding: 70px 0;
`

// PAGE WITH HEADER STYLING
export const PageWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 25px 5% 70px;
`

export const Logo = styled.img`
  height: 90px;
  margin-bottom: 20px;
  padding-right: 25px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderWithButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// DIV BACKGROUND STYLING
export const WhiteBGContainer = styled.div`
  background: ${theme.colors.background.white};
  border-radius: ${theme.borderRadius.div};
  padding: ${props => (props.padding !== undefined ? props.padding : '60px')};
`

export const LightBGContainer = styled(WhiteBGContainer)`
  background: ${theme.colors.background.light};
`

export const MedBGContainer = styled(WhiteBGContainer)`
  background: ${theme.colors.background.medium};
`

// DIVIDER STYLING
export const Divider = styled.div`
  border-top: 1px solid ${theme.colors.sectionDivider};
  width: 100%;
`

// FLEX DIV STYLING
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.gap !== undefined ? props.gap : '20px')};
  width: ${props => props.width};
`

export const CenteredFlexColumn = styled(FlexColumn)`
  align-items: center;
  text-align: center;
`

export const FullHeightCenteredFlexColumn = styled(CenteredFlexColumn)`
  gap: 30px;
  height: 100%;
  max-width: 900px;
`

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => (props.gap !== undefined ? props.gap : '15px')};
`

export const CenteredRowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => (props.gap !== undefined ? props.gap : '30px')};
  width: ${props => props.width};

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`

export const CenteredRowContainerReverse = styled(CenteredRowContainer)`
  @media only screen and (max-width: 800px) {
    flex-wrap: wrap-reverse;
  }
`

export const SpaceBetweenRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${props => (props.gap !== undefined ? props.gap : '15px')};

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const FlexEndButtonErrorContainer = styled(FlexEndContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
  gap: ${props => (props.gap !== undefined ? props.gap : '15px')};
`

export const FullWidthButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }
`

// FORM STYLING
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.gap !== undefined ? props.gap : '20px')};
`

export const PaddedForm = styled(Form)`
  padding: 30px;

  @media only screen and (max-width: 800px) {
    padding: 25px;
  }
`

export const DarkBGPaddedForm = styled(PaddedForm)`
  border-radius: ${theme.borderRadius.div};
  background: ${theme.colors.background.medium};
`

export const CenteredForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
`

export const FormSectionDiv = styled.div`
  display: flex;
  gap: ${props => (props.gap !== undefined ? props.gap : '30px')};
  flex-direction: column;
  background: ${theme.colors.background.white};
  border-radius: ${theme.borderRadius.div};
  border: ${({ error }) => (error ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.background.white}`)};
  padding: 30px 40px;

  @media only screen and (max-width: 800px) {
    padding: 25px;
  }
`

export const FormRow = styled.div`
  display: flex;
  gap: 20px 2%;
  flex-wrap: wrap;
`

export const InputWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: ${props => (props.basis !== undefined ? props.basis : '23.5%')};
  width: ${props => (props.basis !== undefined ? props.basis : 'auto')};
  min-width: 130px;

  @media only screen and (max-width: 727px) {
    flex-grow: 1;
  }
`

// DIALOG STYLING
export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 500px;
    border-radius: ${theme.borderRadius.div};
  }
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`

export const FlexDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
`
