import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { lock } from '../../images/images.js'
import { postSecurityCode, postVerifySecurityCode } from '../../dataClient/apiCalls'
import { CenteredFlexColumn } from '../styles/Containers.js'
import Button, { TextButton } from '../common/Button'
import BodyText from '../styles/Text.js'
import Loading from '../common/Loading'
import ErrorMessage from '../common/ErrorMessage'
import CodeInput from './CodeInput'

const Image = styled.img`
  width: 180px;
  align-items: center;
`

const Bold = styled.span`
  font-weight: 600;
`

const VerificationContainer = styled(CenteredFlexColumn)`
  gap: 15px;
`

function SecurityCodeForm({ status, email, pirId, secondaryId, onVerify = () => {} }) {
  const [loading, setLoading] = useState(true)
  const [codeError, setCodeError] = useState()
  const [code, setCode] = useState(new Array(6).fill(''))

  const [verifyLoading, setVerifyLoading] = useState(false)
  const [verifyError, setVerifyError] = useState()

  const [validationError, setValidationError] = useState(false)

  async function sendNewCode(generate = false) {
    try {
      setCodeError(undefined)
      await postSecurityCode(pirId, generate)
      setLoading(false)
    } catch (e) {
      if (e.response) setCodeError(e.response.data.message)
      setLoading(false)
      console.error('failed to send a security code to the requestor', e)
    }
  }

  useEffect(() => {
    if (status === 'COMPLETED') {
      sendNewCode()
    } else {
      setLoading(false)
    }
  }, [])

  async function verifyCode() {
    setValidationError(false)
    setVerifyError(undefined)
    const joinedCode = code.join('')

    if (joinedCode.length === 6) {
      try {
        setVerifyLoading(true)
        setVerifyError(undefined)
        await postVerifySecurityCode(pirId, joinedCode)
        setVerifyLoading(false)
        onVerify()
      } catch (e) {
        if (e.response) setVerifyError(e.response.data.message)
        setVerifyLoading(false)
        console.error('failed to verify the security code', e)
      }
    } else {
      setValidationError(true)
    }
  }

  if (loading) return <Loading />

  return (
    <CenteredFlexColumn>
      <Image src={lock} />
      {status === 'NEW' && (
        <>
          <BodyText>This PIR has not been paid for.</BodyText>
          <Button>
            <Link to={`/payment/${pirId}`}>Complete Payment</Link>
          </Button>
        </>
      )}
      {['PAID', 'SIGNING', 'READY'].includes(status) && (
        <BodyText>
          This PIR is incomplete and not ready for download. An email will be sent to <Bold>{email}</Bold> when the PIR is ready.
        </BodyText>
      )}
      {status === 'REJECTED' && (
        <BodyText>
          This PIR has been cancelled, an email with the reason was sent to <Bold>{email}</Bold>.
        </BodyText>
      )}
      {status === 'EXPIRED' && (
        <BodyText>
          This PIR has expired as all signatures were not received before the document expired. A refund has been issued to the requestor.
        </BodyText>
      )}
      {status === 'COMPLETED' && (
        <>
          {codeError && <ErrorMessage>There was an error sending a code to your email. Please refresh the page or send a new code.</ErrorMessage>}
          <BodyText>
            A one-time security code has been sent to the requestor&#39;s email address at <Bold>{email}</Bold>. Please enter it here to access the
            response documents pertaining to <Bold>{secondaryId}</Bold>.
          </BodyText>
          <CodeInput code={code} onUpdateCode={e => setCode(e)} />

          <VerificationContainer>
            <Button onClick={verifyCode} loading={verifyLoading}>
              Verify
            </Button>
            {validationError && <ErrorMessage>Please enter a valid 6 character code.</ErrorMessage>}
            {verifyError &&
              (verifyError === 'code invalid' ? (
                <ErrorMessage>The entered code is invalid and may have expired. Please try again.</ErrorMessage>
              ) : (
                <ErrorMessage>There was an error verifying your code. Please try again.</ErrorMessage>
              ))}

            <TextButton onClick={() => sendNewCode(true)} disabled={verifyLoading}>
              Send a new code
            </TextButton>
          </VerificationContainer>
        </>
      )}
    </CenteredFlexColumn>
  )
}

SecurityCodeForm.propTypes = {
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  secondaryId: PropTypes.string.isRequired,
  pirId: PropTypes.string.isRequired,
  onVerify: PropTypes.func
}

export default SecurityCodeForm
