import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stepper, Step, StepButton } from '@mui/material'
import styled from '@emotion/styled'
import { theme } from '../../themes/theme'

const StyledStepButton = styled(StepButton)`
  .MuiStepLabel-alternativeLabel {
    font-family: ${theme.fonts.heading};
    font-size: 12px;
  }

  .Mui-active.MuiStepLabel-iconContainer > svg,
  .MuiStepLabel-iconContainer.Mui-completed.MuiStepLabel-iconContainer > svg {
    color: ${theme.colors.primary};
  }
`

function Carousel({ steps, activeStep, onClickStep = () => {} }) {
  const handleClickStep = step => onClickStep(step)

  return (
    <Box>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StyledStepButton color="inherit" onClick={() => handleClickStep(index)}>
                {label}
              </StyledStepButton>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

Carousel.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  onClickStep: PropTypes.func
}

export default Carousel
