import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import DeleteIcon from '@mui/icons-material/Delete'

import { StyledDialog, FlexEndContainer, FlexDialogContent } from '../styles/Containers'
import Button, { IconButton, SecondaryButton } from '../common/Button'
import BodyText from '../styles/Text'
import useAxiosPrivate from '../../dataClient/hooks/useAxiosPrivate'
import ErrorMessage from '../common/ErrorMessage'

function DeleteFileButton({ pirId, name, onDelete = () => {} }) {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const handleOpen = () => setIsVisible(true)
  const handleClose = () => {
    setError(false)
    setIsVisible(false)
    setIsLoading(false)
  }

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  function handleDelete() {
    setIsLoading(true)

    axiosPrivate
      .post(`/delete/${pirId}/${name}`)
      .then(() => {
        onDelete(name)
        handleClose()
      })
      .catch(e => {
        setIsLoading(false)
        setError(true)
        console.error('failed to delete file', name, e)
      })
  }

  return (
    <div>
      <IconButton color="secondary" onClick={handleOpen} aria-label="Delete file">
        <DeleteIcon />
      </IconButton>
      <StyledDialog fullWidth open={isVisible} onClose={handleClose} keepMounted={false}>
        <FlexDialogContent>
          <BodyText>Are you sure you want to delete this file?</BodyText>
          {error && <ErrorMessage />}
          <FlexEndContainer>
            <SecondaryButton onClick={handleClose} disabled={isLoading}>
              Cancel
            </SecondaryButton>
            <Button onClick={handleDelete} loading={isLoading}>
              Delete
            </Button>
          </FlexEndContainer>
        </FlexDialogContent>
      </StyledDialog>
    </div>
  )
}

DeleteFileButton.propTypes = {
  pirId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func
}

export default DeleteFileButton
