function formatAddress(info) {
  const { address, unit, city, province, postalCode } = info
  const formattedUnit = unit !== '' ? `${unit}-` : ''
  const propertyAddress = `${formattedUnit}${address}, ${city}, ${province} ${postalCode}`
  return propertyAddress
}

function formatName(info) {
  return `${info.firstName} ${info.lastName}`
}

function formatPhone(phone) {
  return phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

function formatSectionInformation(info) {
  const values = {
    Name: formatName(info),
    Address: info.address,
    Phone: formatPhone(info.phone),
    Email: info.email
  }

  if (info.additionalName) values['Additional Name'] = info.additionalName

  return values
}

function formatPIRDetailsForDisplay({ propertyInformation, ownerInformation, strataInformation, requestorInformation } = {}) {
  const formattedDetails = {
    propertyInformation: { 'Property Address': formatAddress(propertyInformation) },
    ownerInformation: formatSectionInformation(ownerInformation),
    requestorInformation: formatSectionInformation(requestorInformation)
  }

  if (strataInformation) {
    formattedDetails.strataInformation = {
      'Strata Details': { 'Strata Number': strataInformation.strataNumber },
      'Contact Information': {
        Role: strataInformation.role,
        ...formatSectionInformation(strataInformation)
      }
    }
  }

  return formattedDetails
}

function formatPIRForOrderSummary({ propertyInformation, ownerInformation, strataInformation, requestorInformation } = {}) {
  const details = {
    'Property Address:': formatAddress(propertyInformation),
    'Owner Name:': formatName(ownerInformation),
    'Permission Given To:': formatName(requestorInformation)
  }

  if (strataInformation) details['Strata Contact:'] = formatName(strataInformation)

  return details
}

export { formatAddress, formatName, formatPhone, formatPIRDetailsForDisplay, formatPIRForOrderSummary }
